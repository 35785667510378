import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, inject } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BooleanToYesNoPipe } from '../../../../pipes/boolean-to-yes-no.pipe';
import { WarehouseService } from '../../../../services/warehouse.service';
import { IWarehouseResponse } from '../../../../interfaces/responses/warehouse-response.interface';
import { IProductForm, IProductGroupForm, IWarehouseForm } from '../../../../interfaces/forms/product-selection-form.interface';
import { IProductSelectionRequest, IProductGroupRequest, IProductRequest, IWarehouseRequest } from '../../../../interfaces/requests/product-selection-request.interface';
import { ProductSelectionService } from '../../../../services/product-selection.service';
import { IProductGroupSettingsResponse, IProductSettinngsResponse, IWarehouseSettingsResponse } from '../../../../interfaces/responses/product-selection-response.interface';

@Component({
  selector: 'app-product-selection-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    RouterModule,
    BooleanToYesNoPipe
  ],
  templateUrl: './product-selection-form.component.html',
  styleUrl: './product-selection-form.component.sass'
})
export class ProductSelectionFormComponent implements OnInit {
  private fb = inject(FormBuilder);
  private warehouseService = inject(WarehouseService);
  private productSelectionService = inject(ProductSelectionService);
  private vendorId!: string;
  private warehouseIdToRemoveArray: string[] = [];
  private productGroupIdToRemoveArray: string[] = [];
  private productIdToRemoveArray: string[] = [];


  form!: FormGroup;
  warehouses: IWarehouseResponse[] = [];
  
  @Input() 
  set id(vendorId: string) {
    this.vendorId = vendorId;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      warehouseArray: this.fb.array<IWarehouseForm>([]),
      productGroupArray: this.fb.array<IProductGroupForm>([]),
      productArray: this.fb.array<IProductForm>([])
    });

    this.warehouseService
      .getWarehouses()
      .subscribe(value => {
        this.warehouses = value;
      },
      error => {
        console.error('Error fetching data: ', error);
      });

    this.productSelectionService
      .getProductSelection(this.vendorId)
      .subscribe(response => {
        response.warehouses.forEach(warehouse => {
          this.initializeWarehouses(warehouse);
        });

        response.productGroups.forEach(productGroup => {
          this.initializeProductGropus(productGroup);
        });

        response.products.forEach(product => {
          this.initializeProduct(product);
        });
      });
  }

  get warehouseArray(): FormArray {
    return this.form.get('warehouseArray') as FormArray;
  }

  warehouse(warehouse: IWarehouseForm): IWarehouseForm {
    return warehouse;
  }

  get productGroupArrayControl(): FormArray {
    return this.form.get('productGroupArray') as FormArray;
  }

  productGroup(index: number): FormControl {
    return this.productGroupArrayControl.at(index) as FormControl;
  }

  get productGroupArray(): IProductGroupForm[] {
    return this.productGroupArrayControl.value;
  }

  get productArray(): FormArray {
    return this.form.get('productArray') as FormArray;
  }

  // Warehouse

  isWarehouseArrayEmpty(): boolean {
    return this.warehouseArray.length > 0;
  }

  initializeWarehouses(warehouseResponse: IWarehouseSettingsResponse): void {
    const warehouseOption: IWarehouseResponse | undefined = this.warehouses
      .find(value => value.id === warehouseResponse.externalId);

    if (warehouseOption) {
      const warehouse: IWarehouseForm = {
        id: warehouseResponse.id,
        externalId: warehouseResponse.externalId,
        symbol: warehouseOption.symbol,
        name: warehouseOption.name,
        isChecked: true
      }
  
      this.warehouseArray
        .push(this.fb.control<IWarehouseForm>(
          warehouse, Validators.required));

      this.warehouses
        .find(value => {
          if (value.symbol === warehouseOption.symbol) {
            value.isChecked = true;
          }
        });
    }
  }

  addWarehouse(option: IWarehouseResponse): void {
    if (!option.isChecked) {
      const warehouse: IWarehouseForm = {
        id: null,
        externalId: option.id,
        symbol: option.symbol,
        name: option.name,
        isChecked: true,
      }
  
      this.warehouseArray
        .push(this.fb.control<IWarehouseForm>(
          warehouse, Validators.required))
      
      this.warehouses
        .find(opt => {
          if (opt.symbol === option.symbol) {
            option.isChecked = true;
          }
        });
    }
  }

  removeWarehouse(index: number): void {
    const warehouse: IWarehouseForm = this.warehouseArray.at(index).value;
    this.warehouseArray.removeAt(index);
    
    this.warehouses.find(opt => {
      if (opt.id === warehouse.externalId) {
        opt.isChecked = false;
      }
    });

    if (warehouse.id) {
      this.warehouseIdToRemoveArray.push(warehouse.id);
    }
  }

  // Product Group

  initializeProductGropus(productGroup: IProductGroupSettingsResponse): void {
    const productGroupForm: IProductGroupForm = {
      id: productGroup.id,
      code: productGroup.code,
      isActive: productGroup.isActive,
      isModifying: false
    };

    this.productGroupArrayControl.push(
      this.fb.group<IProductGroupForm>(
        productGroupForm));
  }

  addProductGroup(): void {
    this.changeModifyingProductGroupToFalse();
    
    const productGroup: IProductGroupForm = {
      id: null,
      code: '',
      isActive: true,
      isModifying: true,
    };

    this.productGroupArrayControl.push(
      this.fb.group<IProductGroupForm>(
        productGroup));
  }

  removeProductGroup(index: number): void {
    const productGroup: IProductGroupForm = this.productGroupArrayControl.at(index).value;
    this.productGroupArrayControl.removeAt(index);

    if (productGroup.id) {
      console.log(productGroup.id);
      this.productGroupIdToRemoveArray.push(productGroup.id);
    }
  }
  
  modifyProductGroup(index: number): void {
    this.changeModifyingProductGroupToFalse();

    const product = this.productGroupArray.at(index);
    this.productGroupArrayControl
      .at(index)
      .patchValue({
        code: product?.code,
        isActive: product?.isActive,
        isModifying: true,
      });
  }

  stopProductGroupModification(index: number) {
    const product: IProductGroupForm = this.productGroupArrayControl.at(index).value;

      this.productGroupArrayControl
        .at(index)
        .patchValue({
          code: product.code,
          isActive: product.isActive,
          isModifying: false
        });
  }
  
  private changeModifyingProductGroupToFalse(): void {
    if (this.productGroupArray.length > 0) {
      const modifyIndex = this.productGroupArray.findIndex(x => x.isModifying === true);
      const product = this.productGroupArray.at(modifyIndex);

      this.productGroupArrayControl
        .at(modifyIndex)
        .patchValue({
          code: product?.code,
          isActive: product?.isActive,
          isModifying: false,
        });
    }
  }

  // Product

  initializeProduct(product: IProductSettinngsResponse): void {
    const productFrom: IProductForm = {
      id: product.id,
      code: product.code,
      partNumber: '',
      isExcluded: product.isExcluded,
      isActive: product.isActive,
      isModifying: false
    };

    this.productArray.push(
      this.fb.group<IProductForm>(
        productFrom));
  }

  addProduct(): void {
    this.changeModifyingProductToFalse();
    
    const product: IProductForm = {
      id: null,
      code: '',
      partNumber: '',
      isExcluded: false,
      isActive: true,
      isModifying: true
      };

    this.productArray.push(
      this.fb.group<IProductForm>(
        product));
  }

  removeProduct(index: number): void {
    const product: IProductForm = this.productArray.at(index).value; 
    this.productArray.removeAt(index);

    if (product.id) {
      console.log(product.id);
      this.productIdToRemoveArray.push(product.id);
    }
  }

  modifyProduct(index: number): void {
    this.changeModifyingProductToFalse();

    const product: IProductForm = this.productArray.at(index).value;
    this.productArray
      .at(index)
      .patchValue({
        code: product.code,
        partNumber: product.partNumber,
        isExcluded: product.isExcluded,
        isActive: product.isActive,
        isModifying: true
      });
  }

  stopProductModification(index: number) {
    const product: IProductForm = this.productArray.at(index).value;

      this.productArray
        .at(index)
        .patchValue({
          code: product.code,
          partNumber: product.partNumber,
          isExcluded: product.isExcluded,
          isActive: product.isActive,
          isModifying: false
        });
  }

  private changeModifyingProductToFalse() {
    if (this.productArray.length > 0) {
      const productArray: IProductForm[] = this.productArray.value;
      const modifyIndex = productArray.findIndex(x => x.isModifying === true);
      const product: IProductForm = this.productArray.at(modifyIndex).value;

      this.productArray
        .at(modifyIndex)
        .patchValue({
          code: product.code,
          partNumber: product.partNumber,
          isExcluded: product.isExcluded,
          isActive: product.isActive,
          isModifying: false
        });
    }
  }
  
  onSubmit() {
    let productGroupRequests: IProductGroupRequest[] = [];
    this.productGroupArray.forEach(value => {
      const productGroupRequest: IProductGroupRequest = {
        id: value.id,
        code: value.code,
        isActive: value.isActive,
      }; 
      productGroupRequests.push(productGroupRequest)
    });

    let warehouseRequests: IWarehouseRequest[] = [];
    (this.warehouseArray.value as IWarehouseForm[]).forEach(value => {
      const warehouseRequest: IWarehouseRequest = {
        id: value.id,
        externalId: value.externalId,
        symbol: value.symbol,
      };
      warehouseRequests.push(warehouseRequest);
    });

    let productRequests: IProductRequest[] = [];
    (this.productArray.value as IProductForm[]).forEach(value => {
      const productRequest: IProductRequest = {
        id: value.id,
        code: value.code,
        isExcluded: value.isExcluded,
        isActive: value.isActive,
      }
      productRequests.push(productRequest);
    });

    const catalogRequest: IProductSelectionRequest = {
      warehouses: warehouseRequests,
      products: productRequests,
      productGroups: productGroupRequests,
    }

    this.warehouseIdToRemoveArray
      .forEach(warehouseId =>
        this.productSelectionService
          .removeWarehouseSetting(this.vendorId, warehouseId)
          .subscribe());

    this.productGroupIdToRemoveArray
      .forEach(productGroupId => 
        this.productSelectionService
          .removeProductGroupSetting(this.vendorId, productGroupId)
          .subscribe());

    this.productIdToRemoveArray
      .forEach(productId => 
        this.productSelectionService
          .removeProductSetting(this.vendorId, productId)
          .subscribe());

    this.productSelectionService
      .updateProductSelection(catalogRequest, this.vendorId)
      .subscribe();

    this.clearRemoveForm();
  }

  private clearRemoveForm(): void {
    this.warehouseIdToRemoveArray = [];
    this.productGroupIdToRemoveArray = [];
    this.productIdToRemoveArray = [];
  }
}
