<fieldset [formGroup]="receiversGroup">
  <div *ngFor="let control of receiverArray.controls; let i = index" class="mb-3">
    <div class="input-group">
      <div class="form-floating">
        <input type="text" class="form-control" placeholder="Receiver" [formControl]="receiver(i)" [id]="'receiver-'+ i">
        <label (for)="'receiver-' + i">Receiver</label>
      </div>
      <button type="button" class="btn btn-outline-secondary" (click)="removeField(i)" *ngIf="i > 0">-</button>
    </div>
  </div>
  <button type="button" class="btn btn-primary" id="addReceiver" (click)="addField()">Add another receiver</button>
</fieldset>