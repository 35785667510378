import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

@Component({
  selector: 'app-sftp-settings-form-child',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    FormsModule
  ],
  templateUrl: './sftp-settings-form-child.component.html',
  styleUrl: './sftp-settings-form-child.component.sass'
})
export class SftpSettingsFormChildComponent implements OnInit {
  vendorSftpSettingsChildForm!: FormGroup;
  fb = inject(FormBuilder);
  
  @Input() formData!: FormGroup;
  @Output() onFormGroupChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>;
  
  ngOnInit(): void {
    this.vendorSftpSettingsChildForm = this.fb.group({
      host: new FormControl<string>('', [Validators.requiredTrue, Validators.min(3), Validators.max(50)]),
      user: new FormControl<string>('', [Validators.requiredTrue, Validators.min(3), Validators.max(50)]),
      password: new FormControl<string>('', [Validators.requiredTrue, Validators.min(3), Validators.max(255)]),
      port: new FormControl<number>(0, Validators.requiredTrue),
      folder: new FormControl<string>('', Validators.required)
    });

    this.addGroupToParent();
  }

  addGroupToParent(): void {
    this.formData.addControl('sftpSettingsGroup', this.vendorSftpSettingsChildForm);
  }

  get host() {
    return this.vendorSftpSettingsChildForm.get('host');
  }
}
