<div class="modal" tabindex="-1" role="dialog" [class.show]="show" [style.display]="show ? 'block' : 'none'">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Set Group Name</h5>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label for="name">Name</label>
          <input type="string" class="form-control" id="name" [(ngModel)]="name">
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onClose()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="onSave()">Save changes</button>
      </div>
    </div>
  </div>
</div>
