import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { ConsoleToggleService } from './services/console-toggle.service';
import { AppCookieService } from './services/app-cookie.service';
import { JWTTokenService } from './services/jwt-token.service';
import { ToastComponent } from "./components/shared/toast/toast.component";

@Component({
    selector: 'app-root',
    standalone: true,
    templateUrl: './app.component.html',
    styleUrl: './app.component.sass',
    imports: [
        CommonModule,
        RouterOutlet,
        ToastComponent
    ]
})
export class AppComponent implements OnInit {
  title = 'Customer Komsa Api';
  token: string | null = null;

  constructor(
    private consoleToggleService: ConsoleToggleService,
    private appCookieService: AppCookieService,
    private jwtTokenService: JWTTokenService) {
      this.token = this.appCookieService.get('token');
      this.jwtTokenService.setToken(this.token ?? '');
      this.consoleToggleService.disableConsoleInProduction();
    }
    
  ngOnInit(): void {
    this.consoleToggleService.disableConsoleInProduction();
    this.token = this.appCookieService.get('token');
    this.jwtTokenService.setToken(this.token ?? '');
  }
}
