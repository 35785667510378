import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { URLHelper } from "../helpers/url.helper";
import { Observable, tap } from "rxjs";
import { IProductResponse } from "../interfaces/responses/product-response.interface";
import { ToastService } from "./toast.service";

@Injectable()
export class ProductService {
  private http = inject(HttpClient);
  private urlHelper = inject(URLHelper);
  private toastService = inject(ToastService);

  getProducts(vendorId: string): Observable<IProductResponse[]> {
    return this.http
      .get<IProductResponse[]>(
        this.urlHelper.GetProducts
          .replace('{vendorId}', vendorId));
  }

  sendProductToVendor(vendorId: string): Observable<void> {
    return this.http
      .get<void>(
        this.urlHelper.SendProductToVendor
          .replace('{vendorId}', vendorId))
      .pipe(
        tap(() => this.toastService.successToast("The products has been sent to the vendor."))
      );
  }
}