import { isPlatformBrowser } from "@angular/common";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";

@Injectable()
export class KomsaApiHttpService {
  host: string = '';
  environment = 'PROD';

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object) {
      if (isPlatformBrowser(this.platformId)) {
        this.host = window.location.origin;
        this.environment = this.getCurrentEnvironment();
      }
    }

  private getCurrentEnvironment(): string {
    let env = '';

    switch(this.host) {
      case 'http://localhost:4200': {
        env = 'DEV';
        break;
      }
      case 'https://customer-komsa-pl-test.komsa.net': {
        env = 'STAGING';
        break;
      }
      case 'https://customer.komsa.pl': {
        env = 'PROD';
        break;
      }
    }

    return env;
  }
}