import { Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { VendorsComponent } from './components/vendors/vendors.component';
import { AuthGuard } from './guards/auth.guard';
import { AddVendorFormComponent } from './components/vendors/add-vendor-form/add-vendor-form.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DetailsComponent } from './components/vendors/details/details.component';
import { ProductsComponent } from './components/downloads/products/products.component';
import { HomeLayoutComponentComponent } from './components/home-layout-component/home-layout-component.component';
import { ProductSelectionFormComponent } from './components/vendors/details/product-selection-form/product-selection-form.component';
import { PreviewProductsComponent } from './components/vendors/details/preview-products/preview-products.component';
import { OrdersComponent } from './components/orders/orders.component';
import { ApiGuard } from './guards/api.guard';
import { EdiGuard } from './guards/edi.guard';
import { OrderFileUploadComponent } from './components/orders/order-file-upload/order-file-upload.component';
import { ExportedOrdersComponent } from './components/exported-orders/exported-orders.component';
import { LoadedOrdersComponent } from './components/loaded-orders/loaded-orders.component';
import { MaterialMovementsComponent } from './components/vmi/material-movements/material-movements.component';
import { VmiGuard } from './guards/vmi.guard';
import { InventoryComponent } from './components/vmi/inventory/inventory.component';
import { SalesComponent } from './components/vmi/sales/sales.component';
import { MaterialMovementRecordsComponent } from './components/vmi/material-movement-records/material-movement-records.component';

export const routes: Routes = [
  {
    path: '',
    component: HomeLayoutComponentComponent,
    canActivate: [AuthGuard],
    children: [

      {
        path: '',
        component: DashboardComponent,
        title: 'Dashboard',
      },
      {
        path: 'vendors',
        component: VendorsComponent,
        title: 'Vendors',
        canActivate: [ApiGuard],
      },
      {
        path: 'vendors',
        children: [
          {
            path: 'add',
            component: AddVendorFormComponent,
            title: 'Vedndors | Add',
            canActivate: [ApiGuard],
          },
          {
            path: ':id',
            canActivate: [ApiGuard],
            children: [
              {
                path: '',
                component: DetailsComponent,
                title: 'Vendor Details',
              },
              {
                path: 'product-selection-form',
                component: ProductSelectionFormComponent,
                title: 'Product Selection Frorm',
              },
              {
                path: 'preview-products',
                component: PreviewProductsComponent,
                title: 'Preview Products'
              }
            ]
          }
        ]
      },
      {
        path: 'orders',
        component: OrdersComponent,
        title: 'Orders',
        canActivate: [EdiGuard],
      },
      {
        path: 'upload-order-files',
        component: OrderFileUploadComponent,
        title: 'Upload Order Files',
        canActivate: [EdiGuard],
      },
      {
        path: 'exported-orders',
        component: ExportedOrdersComponent,
        title: 'Exported Orders',
        canActivate: [EdiGuard],
      },
      {
        path: 'loaded-orders',
        component: LoadedOrdersComponent,
        title: 'Loaded Orders',
        canActivate: [EdiGuard],
      },
      {
        path: 'material-movements',
        component: MaterialMovementsComponent,
        title: 'Material Movements',
        canActivate: [VmiGuard],
      },
      {
        path: 'inventories',
        component: InventoryComponent,
        title: 'Inventories',
        canActivate: [VmiGuard],
      },
      {
        path: 'sales',
        component: SalesComponent,
        title: 'Sales',
        canActivate: [VmiGuard],
      },
      {
        path: 'material-movement-records',
        component: MaterialMovementRecordsComponent,
        title: 'Material Movement Records',
        canActivate: [VmiGuard],
      }
    ]
  },
  {
    path: 'sign-in',
    component: LoginComponent,
    title: 'Sign-In',
  },
  {
    path: 'download/products/:id/:fileName',
    component: ProductsComponent,
    title: 'Download'
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    title: 'Page not found',
  },
];
