<div class="popup-container" *ngIf="isVisible">
  <div class="popup-content">
    <div class="popup-header">
      <h5>Order groups</h5>
      <th><input type="text" class="form-control" placeholder="Filter Name" [(ngModel)]="name" (ngModelChange)="applyFilter()"></th>
    </div>
    <div class="popup-body">
      <div class="table-container">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Order Quantity</th>
              <th scope="col">Date</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let orderGroup of orderGroups; let i = index"
              [class.selected]="selectedRowIndex === i"
              (dblclick)="selectAndAcceptItem(i)"
              (click)="selectRow(i)">
              <td>{{i + 1 }}</td>
              <td>{{ orderGroup.name }}</td>
              <td>{{ orderGroup.orderQuantity }}</td>
              <td>{{ orderGroup.date | date: 'short' }}</td>
            </tr>
          </tbody>
        </table>
        <nav>
          <ul class="pagination justify-content-center">
            <li class="page-item" [class.disabled]="position === 0">
              <a class="page-link" (click)="previousPage()" tabindex="-1">Previous</a>
            </li>
            <li class="page-item" *ngFor="let page of [].constructor(totalPages); let i = index" [class.active]="position === i">
              <a class="page-link" (click)="goToPage(i + 1)">{{ i + 1 }}</a>
            </li>
            <li class="page-item" [class.disabled]="isLastPage">
              <a class="page-link" (click)="nextPage()">Next</a>
            </li>
          </ul>
        </nav>
      </div>
      <div class="popup-footer">
        <button class="btn btn-primary mb-3" (click)="closePopup()">Close</button>
        <button class="btn btn-secondary mb-3" (click)="closeAndShowAll()">Show All Orders</button>
      </div>
    </div>
  </div>
</div>
