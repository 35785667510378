<nav class="navbar navbar-expand-lg navbar-light bg-light d-flex justify-content-between">
  <div class="container-fluid">
    <span class="navbar-brand">Statistics</span>
    <div class="collapse navbar-collapse">
      <ul class="navbar-nav d-flex justify-content-center align-items-center mb-2 mb-lg-0">
        <li class="nav-item">
          <span class="nav-link">Total Orders Selected: {{ totalOrdersSelected }}</span>
        </li>
        <li class="nav-item">
          <span class="nav-link">Total Quantity: {{ totalQuantity }}</span>
        </li>
        <li class="nav-item">
          <span class="nav-link">Unit Price: {{ averageUnitPrice | number:'1.2-2' }}</span>
        </li>
      </ul>
        <button class="btn btn-primary ms-auto " (click)="toggleDetails()">
          Show Details
        </button>
    </div>
  </div>
</nav>

<div *ngIf="showDetails" class="statistics-popup">
  <div class="popup-overlay" (click)="toggleDetails()"></div>
  <div class="popup">
    <div class="popup-content">
      <h5>Grouped Statistics by Code</h5>
      <table class="table table-bordered table-striped">
      <thead>
        <tr>
          <th>Code</th>
          <th>Ean</th>
          <th>Name</th>
          <th>Total Quantity</th>
          <th>Average Unit Price</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let stat of groupedStatistics">
          <td>{{ stat.code }}</td>
          <td>{{ stat.ean }}</td>
          <td>{{ stat.name }}</td>
          <td>{{ stat.totalQuantity }}</td>
          <td>{{ stat.averageUnitPrice | number:'1.2-2' }}</td>
        </tr>
      </tbody>
      </table>
    </div>
    <div class="popup-footer">
      <button class="btn btn-primary mb-3" (click)="toggleDetails()">Close</button>
    </div>
  </div>
</div>