<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="sign-in-form">
    <h2>Login</h2>

    <div class="form-floating mb-3">
      <input type="text" class="form-control" id="login" placeholder="Login" formControlName="login">
      <label for="login">Login</label>
    </div>

    <div class="form-floating mb-3">
      <input type="password" class="form-control" id="password" placeholder="Password" formControlName="password">
      <label for="password">Password</label>
    </div>

    <div class="col-12">
      <button type="submit" class="btn btn-primary">Sign in</button>
    </div>
  </div>
</form>