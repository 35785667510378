import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { VendorInfoInterface } from "../interfaces/vendor-info.interface";
import { map, Observable, tap } from "rxjs";
import { IVendorRequest } from "../interfaces/requests/vendor-request.interface";
import { URLHelper } from "../helpers/url.helper";
import { IVendorResponse } from "../interfaces/responses/vendor-response.interface";
import { Router } from "@angular/router";
import { VendorAddResponse } from "../interfaces/responses/vendor-add-response.interface";
import { ToastService } from "./toast.service";

@Injectable()
export class VendorService {
  private router = inject(Router);
  private http = inject(HttpClient);
  private urlHelper = inject(URLHelper);
  private toastService = inject(ToastService);

  getVendorInfoList(): Observable<VendorInfoInterface[]> {
    return this.http
      .get<VendorInfoInterface[]>(this.urlHelper.Vendors);
  }

  addVendor(vendor: IVendorRequest): Observable<VendorAddResponse> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded');
    
    let params = new HttpParams()
      .set('komsaCode', vendor.komsaCode)
      .set('secret', vendor.secret)
      .set('dispatchType', vendor.dispatchType)
      .set('fileType', vendor.fileType);
     
    vendor.receivers?.forEach(receiver => {
      params = params.append('receivers', receiver);
    });
      
    if (vendor.vendorSftpSettings) {
      params = params.append('sftpSettings.host', vendor.vendorSftpSettings.host)
      params = params.append('sftpSettings.user', vendor.vendorSftpSettings.user)
      params = params.append('sftpSettings.password', vendor.vendorSftpSettings.password)
      params = params.append('sftpSettings.port', vendor.vendorSftpSettings.port);
    }
    
    return this.http
      .post<VendorAddResponse>(
        this.urlHelper.AddVendor,
        params,
        { headers })
      .pipe(
        map((response: VendorAddResponse) => {
          this.router.navigateByUrl('/vendors/' + response.value);
          return response;
      }),
        tap(() => this.toastService.successToast("Vendor has been added successfully."))
      );
  }
  
  getVendorById(id: string): Observable<IVendorResponse> {
    return this.http
      .get<IVendorResponse>(this.urlHelper.GetVendorById.replace('id', id));
  }
}