import { HttpClient, HttpParams } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { URLHelper } from "../../helpers/url.helper";
import { Observable } from "rxjs";
import { IPagination } from "../../interfaces/shared/pagination.interface";
import { IPaginatedResponse } from "../../interfaces/shared/paginated-response.interface";
import { EqualityType } from "../../enums/equality-type.enum";
import { IGroupedInventory } from "../../interfaces/vmi/grouped-inventory.interface";

@Injectable()
export class InventoryService {
  private http = inject(HttpClient);
  private urlHelper = inject(URLHelper);

  getInventories(
    pagination: IPagination,
    customerServicePoint: string,
    productCode: string,
    quantity: number | null,
    equalityType: EqualityType
  ): Observable<IPaginatedResponse<IGroupedInventory>> {
    let params = new HttpParams();
    params = params.append('page', pagination.page.toString());
    params = params.append('pageSize', pagination.pageSize.toString());

    if (customerServicePoint) {
      params = params.append('customerServicePoint', customerServicePoint);
    }

    if (productCode) {
      params = params.append('productCode', productCode);
    }

    if (quantity !== null) {
      params = params.append('quantity', quantity.toString());
      params = params.append('equalityType', equalityType.toString());
    }
    
    return this.http
      .get<IPaginatedResponse<IGroupedInventory>>(
        this.urlHelper.GetInventories, { params });
  }
}