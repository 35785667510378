<div class="navbar">
  <label class="navbar-brand">Products</label>
</div>
<table class="table table-sm table-light table-striped table-hover">
  <thead>
    <tr>
      <th scope="col" class="text-center">#</th>
      <th scope="col" class="col-1">Code</th>
      <th scope="col" class="col-1">PartNumber</th>
      <th scope="col" class="col-1">Ean</th>
      <th scope="col">Name</th>
      <th scope="col" class="col-1">Manufacturer</th>
      <th scope="col">Price</th>
      <th scope="col" class="col-1">Quantity</th>
    </tr>
  </thead>
  <tbody>
      <tr *ngFor="let product of products; let i = index">
        <th scope="row" class="text-center align-middle">
          {{i+1}}
        </th>
        <td class="align-middle">
          {{product.code}}
        </td>
        <td class="align-middle">
          {{product.partNumber}}
        </td>
        <td class="align-middle">
          {{product.ean}}
        </td>
        <td class="align-middle">
          {{product.name}}
        </td>
        <td class="align-middle">
          {{product.manufacturer}}
        </td>
        <td class="align-middle">
          {{product.price}}
        </td>
        <td class="align-middle">
          {{product.quantity}}
        </td>
      </tr>
  </tbody>
</table>