<div class="container my-4">
  <h2 class="text-center mb-4">Loaded Orders To Optima</h2>
  
  <div class="items-per-page d-flex justify-content: flex-end">
    <label for="itemsPerPageSelect">Items per page:</label>
    <select id="itemsPerPageSelect" class="form-control" [(ngModel)]="limit" (change)="updatePage()">
      <option *ngFor="let option of [25, 50, 75, 100]" [value]="option">{{option}}</option>
    </select>
  </div>

  <table class="table table-hover table-striped">
    <thead class="thead-dark">
      <tr>
        <th scope="col" class="text-center">Order Number</th>
        <th scope="col" class="text-center">Document Number</th>
        <th scope="col" class="text-center">Delivery Note</th>
        <th scope="col" class="text-center">Document Status</th>
        <th scope="col" class="text-center">Loaded Date</th>
        <th scope="col" class="text-center">Completed</th>
      </tr>
      <tr>
        <th><input type="text" class="form-control" placeholder="Filter Order Number" [(ngModel)]="filters.orderNumber" (ngModelChange)="applyFilter()"></th>
        <th><input type="text" class="form-control" placeholder="Filter Document Number" [(ngModel)]="filters.documentNumber" (ngModelChange)="applyFilter()"></th>
        <th><input type="text" class="form-control" placeholder="Filter Delivery Note" [(ngModel)]="filters.deliveryNote" (ngModelChange)="applyFilter()"></th>
        <th>
          <select class="form-control" [(ngModel)]="filters.documentStatus" (ngModelChange)="applyFilter()">
            <option *ngFor="let status of documentStatusOptions" [value]="status">{{status}}</option>
          </select>
        </th>
        <th><app-dual-date-picker (dateChange)="onDateChange($event)"></app-dual-date-picker></th>
        <th class="text-center">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="isCompleted" id="completedYes" value="true" [(ngModel)]="filters.isCompleted" (ngModelChange)="applyFilter()">
            <label class="form-check-label" for="loadedYes">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="isCompleted" id="completedNo" value="false" [(ngModel)]="filters.isCompleted" (ngModelChange)="applyFilter()">
            <label class="form-check-label" for="loadedNo">No</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="isCompleted" id="completedAll" [value]="null" [(ngModel)]="filters.isCompleted" (ngModelChange)="applyFilter()">
            <label class="form-check-label" for="loadedAll">All</label>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="isLoading">
        <td colspan="7" class="text-center">Loading...</td>
      </tr>
      <ng-container *ngFor="let loadedOrder of loadedOrders; let i = index">
        <tr (click)="toggleDetails(i)">
          <td class="text-center">{{ loadedOrder.orderNumber }}</td>
          <td class="text-center">{{ loadedOrder.documentNumber }}</td>
          <td class="text-center">{{ loadedOrder.deliveryNote }}</td>
          <td class="text-center">{{ loadedOrder.documentStatus }}</td>
          <td class="text-center">{{ loadedOrder.created | date }}</td>
          <td class="text-center">{{ loadedOrder.isCompleted | booleanToYesNo}}</td>
        </tr>
        <tr *ngIf="loadedOrder.showDetails" class="expandable-row">
          <td colspan="10">
            <div class="detail-container">
              <h5>Order Lines</h5>
              <table class="table table-sm table-bordered table-striped">
                <thead class="thead-light">
                  <tr>
                    <th>#</th>
                    <th>Ean</th>
                    <th>Code</th>
                    <th>Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let loadedOrderLine of loadedOrder.loadedOrderLines; let j = index">
                    <td>{{ j + 1 }}</td>
                    <td>{{ loadedOrderLine.ean }}</td>
                    <td>{{ loadedOrderLine.code }}</td>
                    <td>{{ loadedOrderLine.quantity }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>

  <nav>
    <ul class="pagination justify-content-center">
      <li class="page-item" [class.disabled]="position === 0">
        <a class="page-link" (click)="previousPage()" tabindex="-1">Previous</a>
      </li>
      <li class="page-item" *ngFor="let page of [].constructor(totalPages); let i = index" [class.active]="position === i">
        <a class="page-link" (click)="goToPage(i + 1)">{{ i + 1 }}</a>
      </li>
      <li class="page-item" [class.disabled]="isLastPage">
        <a class="page-link" (click)="nextPage()">Next</a>
      </li>
    </ul>
  </nav>

</div>