<!-- <app-order-file-upload/> -->

<div class="container my-4">

  <app-statistics-navbar
    [totalOrdersSelected]="selectedOrders.length"
    [totalQuantity]="totalQuantity"
    [averageUnitPrice]="averageUnitPrice"
    [groupedStatistics]="groupedStatistics">
  </app-statistics-navbar>

  <h2 class="text-center mb-4">Orders</h2>
  
  <div class="items-per-page d-flex justify-content: flex-end">
    <label for="itemsPerPageSelect">Items per page:</label>
    <select id="itemsPerPageSelect" class="form-control" [(ngModel)]="limit" (change)="updatePage()">
      <option *ngFor="let option of [25, 50, 75, 100]" [value]="option">{{option}}</option>
    </select>
    <button class="btn btn-primary ms-2" (click)="setOrderGroupName()">Save Group</button>
    <button class="btn btn-primary ms-2" (click)="showGroups()">Show Groups</button>
    <button class="btn btn-primary ms-2" (click)="exportOrders()">Export Orders to CSV</button>
    <button class="btn btn-primary ms-2" (click)="exportOrdersToXml()">Export Orders to XML</button>
  </div>

  <table class="table table-hover table-striped">
    <thead class="thead-dark">
      <tr>
        <th scope="col" class="text-center"><input type="checkbox" (change)="toggleAllSelection($event)"></th>
        <th scope="col" class="text-center">Order Number</th>
        <th scope="col" class="text-center">Upload Date</th>
        <th scope="col" class="text-center">Buyer Name</th>
        <th scope="col" class="text-center">Buyer ILN</th>
        <th scope="col" class="text-center">Delivery ILN</th>
      </tr>
      <tr>
        <th></th>
        <th><input type="text" class="form-control" placeholder="Filter Order Number" [(ngModel)]="orderFilter.orderNumber" (ngModelChange)="applyFilter()"></th>
        <th><app-dual-date-picker (dateChange)="onDateChange($event)"></app-dual-date-picker></th>
        <th><input type="text" class="form-control" placeholder="Filter" [(ngModel)]="orderFilter.buyerName" (ngModelChange)="applyFilter()"></th>
        <th><input type="text" class="form-control" placeholder="Filter" [(ngModel)]="orderFilter.buyerILN" (ngModelChange)="applyFilter()"></th>
        <th><input type="text" class="form-control" placeholder="Filter" [(ngModel)]="orderFilter.deliveryILN" (ngModelChange)="applyFilter()"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="isLoading">
        <td colspan="7" class="text-center">Loading...</td>
      </tr>
      <ng-container *ngFor="let order of orders; let i = index">
        <tr (click)="toggleDetails(i)" [ngClass]="{
          'table-active': order.showDetails, 
          'table-danger': getRowClass(order) === 'table-danger',
          'table-warning': getRowClass(order) === 'table-warning',
          'table-success': getRowClass(order) === 'table-success',
          'table-selected': order.isSelected}">
          <td class="text-center align-middle">
            <input type="checkbox" [checked]="order.isSelected" (click)="$event.stopPropagation()" (change)="toggleSelection(order)">
          </td>
          <td class="text-center">{{ order.orderNumber }}</td>
          <td class="text-center">{{ order.uploadDate | date }}</td>
          <td class="text-center">{{ order.buyerName }}</td>
          <td class="text-center">{{ order.buyerILN }}</td>
          <td class="text-center">{{ order.deliveryILN }}</td>
        </tr>
        <tr *ngIf="order.showDetails" class="expandable-row">
          <td colspan="10">
            <div class="detail-container">
              <h5>Order Lines</h5>
              <table class="table table-sm table-bordered table-striped">
                <thead class="thead-light">
                  <tr>
                    <th>#</th>
                    <th>Ean</th>
                    <th>Code</th>
                    <th>Name</th>
                    <th>Quantity</th>
                    <th>Unit Price</th>
                    <th>Komsa</th>
                    <th>Project</th>
                    <th>Lenovo</th>
                    <th>RO</th>
                    <th>Loaded</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let orderLine of order.orderLines; let j = index" [ngClass]="getOrderLineClass(orderLine)">
                    <td>{{ j + 1 }}</td>
                    <td>{{ orderLine.ean }}</td>
                    <td>{{ orderLine.code }}</td>
                    <td>{{ orderLine.name }}</td>
                    <td>{{ orderLine.quantity }}</td>
                    <td class="unit-price-hover">
                      <span (dblclick)="openEditPriceModal(orderLine)" style="cursor: pointer;">
                        {{ orderLine.orderedUnitPrice | number:'1.2-2' }} PLN
                      </span>
                    </td>
                    <td>{{ orderLine.warehouse.komsaStock }}</td>
                    <td>{{ orderLine.warehouse.projectStock }}</td>
                    <td>{{ orderLine.warehouse.lenovoStock }}</td>
                    <td>{{ orderLine.warehouse.reservation }}</td>
                    <td>{{ orderLine.isCompleted | booleanToYesNo }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>

  <nav>
    <ul class="pagination justify-content-center">
      <li class="page-item" [class.disabled]="position === 0">
        <a class="page-link" (click)="previousPage()" tabindex="-1">Previous</a>
      </li>
      <li class="page-item" *ngFor="let page of [].constructor(totalPages); let i = index" [class.active]="position === i">
        <a class="page-link" (click)="goToPage(i + 1)">{{ i + 1 }}</a>
      </li>
      <li class="page-item" [class.disabled]="isLastPage">
        <a class="page-link" (click)="nextPage()">Next</a>
      </li>
    </ul>
  </nav>

  <app-edit-price-modal
    [orderLine]="editingOrderLine"
    [show]="showModal"
    (save)="saveUnitPrice($event)"
    (close)="closeModal()"
    ></app-edit-price-modal>
  
  <app-set-group-name
    [show]="setGroupName"
    (save)="saveGroup($event)"
    (close)="closeSetGroupName()"
    ></app-set-group-name>
  
  <app-set-export-csv-name
    [show]="setCsvFileName"
    (save)="exportOrdersToCsv($event)"
    (close)="closeSetExportCsvName()"
    ></app-set-export-csv-name>

  <app-groups
    [isVisible]="isGroupsVisible"
    (selectionAccepted)="orderGroupSelection($event)"
    (close)="closeGroups()"
    ></app-groups>


</div>
