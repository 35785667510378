<nav class="sidebar">
  <div class="sidebar-header">
    <span class="user-greeting" *ngIf="isLoggedIn ?? false">
      Welcome, {{ user }}
    </span>
  </div>

  <ul class="sidebar-menu" *ngIf="isLoggedIn ?? false">
    <li class="sidebar-item" *ngIf="showApi()">
      <a class="sidebar-link" [routerLink]="['vendors']" routerLinkActive="active">
        <i class="fas fa-industry"></i> Vendors
      </a>
    </li>

    <li class="sidebar-item" *ngIf="showEdi()">
      <a class="sidebar-link" [routerLink]="['orders']" routerLinkActive="active">
        <i class="fas fa-shopping-cart"></i> Orders
      </a>
    </li>

    <li class="sidebar-item" *ngIf="showEdi()">
      <a class="sidebar-link" [routerLink]="['exported-orders']" routerLinkActive="active">
        <i class="fas fa-file-export"></i> Exported Orders
      </a>
    </li>

    <li class="sidebar-item" *ngIf="showEdi()">
      <a class="sidebar-link" [routerLink]="['loaded-orders']" routerLinkActive="active">
        <i class="fas fa-box"></i> Loaded Orders
      </a>
    </li>

    <li class="sidebar-item" *ngIf="showEdi()">
      <a class="sidebar-link" [routerLink]="['upload-order-files']" routerLinkActive="active">
        <i class="fas fa-upload"></i> Upload Order Files
      </a>
    </li>

    <li class="sidebar-item" *ngIf="showVmi()">
      <a class="sidebar-link" [routerLink]="['material-movements']" routerLinkActive="active">
        <i class="fas fa-exchange-alt"></i> Material Movements
      </a>
    </li>

    <li class="sidebar-item" *ngIf="showVmi()">
      <a class="sidebar-link" [routerLink]="['inventories']" routerLinkActive="active">
        <i class="fas fa-warehouse"></i> Inventories
      </a>
    </li>

    <li class="sidebar-item" *ngIf="showVmi()">
      <a class="sidebar-link" [routerLink]="['sales']" routerLinkActive="active">
        <i class="fas fa-chart-line"></i> Sales
      </a>
    </li>

    <li class="sidebar-item" *ngIf="showVmi()">
      <a class="sidebar-link" [routerLink]="['material-movement-records']" routerLinkActive="active">
        <i class="fas fa-clipboard-check"></i> Material Movement Records
      </a>
    </li>

    <div class="sidebar-footer" *ngIf="isLoggedIn ?? false">
      <a class="sidebar-link logout" [routerLink]="['sign-in']" (click)="logout()">
        <i class="fas fa-sign-out-alt"></i> Sign Out
      </a>
    </div>
  </ul>
</nav>
