import { HttpClient } from '@angular/common/http';
import { Component, inject } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { IAuthRequest } from '../../interfaces/requests/auth-request.interface';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-login',
    standalone: true,
    templateUrl: './login.component.html',
    styleUrl: './login.component.sass',
    imports: [
        ReactiveFormsModule,
        CommonModule
    ]
})
export class LoginComponent {
  fb = inject(FormBuilder);
  http = inject(HttpClient);
  router = inject(Router);

  form = this.fb.nonNullable.group({
    login: ['', Validators.required],
    password: ['', Validators.required],
  });

  constructor(private authService: AuthService) {}

  onSubmit(): void {
    const authRequest: IAuthRequest = {
      login: this.form.getRawValue().login,
      password: this.form.getRawValue().password
    }

    this.authService
      .logIn(authRequest);
  }
}
