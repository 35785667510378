import { Injectable, OnInit, inject } from "@angular/core";
import { KomsaApiHttpService } from "./komsa-api-http.service";

@Injectable()
export class ConsoleToggleService{
  komsaApiHttpService = inject(KomsaApiHttpService);

  disableConsoleInProduction(): void {
    if (this.komsaApiHttpService.environment === 'PROD') {
      console.warn('Console output is disabled on production.');
      console.log = function (): void { };
      console.debug = function (): void { };
      console.warn = function (): void { };
      console.info = function (): void { };
    }
  }
}