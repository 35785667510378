import { Component, Input, OnInit, inject } from '@angular/core';
import { VendorService } from '../../../services/vendor.service';
import { IVendorResponse } from '../../../interfaces/responses/vendor-response.interface';
import { CommonModule } from '@angular/common';
import { URLHelper } from '../../../helpers/url.helper';
import { RouterLink } from '@angular/router';
import { ProductService } from '../../../services/product.service';

@Component({
  selector: 'app-details',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink
  ],
  templateUrl: './details.component.html',
  styleUrl: './details.component.sass'
})
export class DetailsComponent implements OnInit {
  vendorService = inject(VendorService);
  urlHelper = inject(URLHelper);
  productService = inject(ProductService);
  
  vendorId!: string;
  vendor: IVendorResponse | undefined;
  
  @Input() 
  set id(vendorId: string) {
    this.vendorId = vendorId;
  }

  ngOnInit(): void {
    this.vendorService
      .getVendorById(this.vendorId)
      .subscribe(response => {
        this.vendor = response;
      });
  }
  
  sendProductsToVendor(): void {
    this.productService
      .sendProductToVendor(this.vendorId)
      .subscribe();
  }

  getDownloadLink(extension: string): string {
    return this.urlHelper.ProductDownloadLinkForUser
      .replace('{extension}', extension)
      .replace('{vendorLinkBase}', this.vendor?.vendorLinkBase ?? '');
  }
}
