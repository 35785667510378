<div class="container">
  <!-- Filters -->
  <div class="filters-container">
    <!-- Filter by Document Number -->
    <div class="filter-item">
      <label for="document-filter">Docuemnt:</label>
      <input
        id="document-filter"
        type="text"
        [(ngModel)]="documentNumberFilter"
        (input)="onFilterChange()"
        placeholder="Search Document">
    </div>

    <!-- Filter by CSP -->
    <div class="filter-item">
      <label for="csp-filter">CSP:</label>
      <input
        id="csp-filter"
        type="text"
        [(ngModel)]="customerServicePointFilter"
        (input)="onFilterChange()"
        placeholder="Search CSP">
    </div>

    <!-- Filter by Product Code -->
    <div class="filter-item">
      <label for="product-code-filter">Product Code:</label>
      <input
        id="product-code-filter"
        type="text"
        [(ngModel)]="supplierProductCodeFilter"
        (input)="onFilterChange()"
        placeholder="Search Product Code">
    </div>

    <!-- Filter by Delivery Document Number -->
    <div class="filter-item">
      <label for="delivery-document-filter">Delivery Document:</label>
      <input
        id="delivery-document-filter"
        type="text"
        [(ngModel)]="deliveryDocumentNumberFilter"
        (input)="onFilterChange()"
        placeholder="Search Delivey Document">
    </div>

    <!-- Filter by Serial Number -->
    <div class="filter-item">
      <label for="serial-number-filter">Serial Number:</label>
      <input
        id="serial-number-filter"
        type="text"
        [(ngModel)]="serialNumberFilter"
        (input)="onFilterChange()"
        placeholder="Search Serial Number">
    </div>
  </div>

  <!-- Tabs for TypeOfMovement -->
  <div class="tabs-container">
    <ul class="tabs">
      <li 
        *ngFor="let type of typeOfMovement" 
        [class.active]="selectedType === type" 
        (click)="selectTab(type)">
        {{ type }}
      </li>
    </ul>
  </div>

  <!-- Export to Excel -->
  <div class="export-container">
    <!-- Date Range Selection -->
    <div class="date-range">
      <label>Start Date:</label>
      <input
        type="date"
        [(ngModel)]="startDate"
        id="start-date"
      />
  
      <label>End Date:</label>
      <input
        type="date"
        [(ngModel)]="endDate"
        id="end-date"
      />
    </div>
  
    <!-- Export to Excel Button -->
    <button class="export-btn" (click)="exportToExcel()">
      <i class="fas fa-file-excel"></i> Export to Excel
    </button>
  </div>

  <!-- Table -->
  <table class="data-table">
    <thead>
      <tr>
        <th>Delivery Date</th>
        <th>Document Number</th>
        <th>Position</th>
        <th>Customer Showroom</th>
        <th>Supplier Product Code</th>
        <th>Quantity</th>
        <th>Value</th>
        <th>Delivery Document Number</th>
        <th>Serial Number</th>
        <th>Notification Order Number</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let record of data">
        <td>{{ record.deliveryDate | date:'dd/MM/yyyy' }}</td>
        <td>{{ record.documentNumber }}</td>
        <td>{{ record.position }}</td>
        <td>{{ record.customerShowroom }}</td>
        <td>{{ record.supplierProductCode }}</td>
        <td>{{ record.quantity }}</td>
        <td>{{ record.value | number:'1.2-2' }}</td>
        <td>{{ record.deliveryDocumentNumber }}</td>
        <td>{{ record.serialNumber }}</td>
        <td>{{ record.notificationOrderNumber }}</td>
      </tr>
    </tbody>
  </table>

  <!-- Pagination -->
  <div class="pagination-container">
    <!-- Page Navigation -->
    <div class="pagination">
      <button [disabled]="metadata.page === 1" (click)="onPageChange(metadata.page - 1)">
        Previous
      </button>
      
      <!-- Jump to Page Input -->
      <span>
        Page
        <input
          type="number"
          class="page-jump"
          [(ngModel)]="customPageInput"
          (keydown.enter)="jumpToPage()"
          [min]="1"
          [max]="metadata.totalPages"
        />
        of {{ metadata.totalPages }}
      </span>

      <button
        [disabled]="data.length < metadata.pageSize"
        (click)="onPageChange(metadata.page + 1)"
      >
        Next
      </button>
    </div>
  
    <!-- Records per Page Selection -->
    <div class="page-size-selector">
      <span>Records per page:</span>
      <button
        *ngFor="let size of pageSizeOptions"
        [class.active]="metadata.pageSize === size"
        (click)="setPageSize(size)"
      >
        {{ size }}
      </button>
    </div>
  </div>
</div>
