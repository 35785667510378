import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { CurrentUserService } from './services/current-user.service';
import { HttpClientModule, provideHttpClient, withFetch } from '@angular/common/http';
import { AuthService } from './services/auth.service';
import { AuthInterceptorProvider } from './providers/auth.provider';
import { VendorService } from './services/vendor.service';
import { URLHelper } from './helpers/url.helper';
import { KomsaApiHttpService } from './services/komsa-api-http.service';
import { DownloadProductsService } from './services/download-products.service';
import { ConsoleToggleService } from './services/console-toggle.service';
import { WarehouseService } from './services/warehouse.service';
import { ProductSelectionService } from './services/product-selection.service';
import { ProductService } from './services/product.service';
import { AppCookieService } from './services/app-cookie.service';
import { JWTTokenService } from './services/jwt-token.service';
import { provideAnimations } from '@angular/platform-browser/animations';
import { FileSaverService } from './services/file-saver.service';
import { ErrorHandlingInterceptorProvider } from './providers/error-handling.provider';
import { MaterialMovementService } from './services/vmi/material-movement.service';
import { InventoryService } from './services/vmi/inventory.service';
import { SaleService } from './services/vmi/sale.service';
import { MaterialMovementRecordService } from './services/vmi/material-movement-record.service';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withComponentInputBinding()),
    provideClientHydration(),
    provideHttpClient(withFetch()),
    provideAnimations(),
    importProvidersFrom(HttpClientModule),
    CurrentUserService,
    AuthService,
    AuthInterceptorProvider,
    ErrorHandlingInterceptorProvider,
    VendorService,
    MaterialMovementService,
    MaterialMovementRecordService,
    InventoryService,
    SaleService,
    WarehouseService,
    ProductSelectionService,
    ProductService,
    FileSaverService,
    URLHelper,
    KomsaApiHttpService,
    DownloadProductsService,
    ConsoleToggleService,
    AppCookieService,
    JWTTokenService,
  ],
};
