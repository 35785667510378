import { Component, EventEmitter, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-dual-date-picker',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './dual-date-picker.component.html',
  styleUrl: './dual-date-picker.component.sass'
})
export class DualDatePickerComponent {
  fromDate: string | null = null;
  toDate: string | null = null;

  @Output() dateChange = new EventEmitter<{ fromDate: string | null, toDate: string | null }>();

  onFromDateChange(event: Event): void {
    this.fromDate = (event.target as HTMLInputElement).value;
    this.emitDateChange();
  }

  onToDateChange(event: Event): void {
    this.toDate = (event.target as HTMLInputElement).value;
    this.emitDateChange();
  }

  emitDateChange(): void {
    this.dateChange.emit({ fromDate: this.fromDate, toDate: this.toDate });
  }
}
