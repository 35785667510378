import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { OrderGroupService } from '../../../services/order-group.service';
import { IOrderGroup } from '../../../interfaces/order-group.interface';
import { FormsModule } from '@angular/forms';

interface Item {
  name: string;
  orderQuantity: number;
  datetime: Date;
  isSelected?: boolean;
}

@Component({
  selector: 'app-groups',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule
  ],
  templateUrl: './groups.component.html',
  styleUrl: './groups.component.sass'
})
export class GroupsComponent implements OnInit{
  @Input() isVisible: boolean = false;
  @Output() selectionAccepted = new EventEmitter<string | undefined>();
  @Output() close = new EventEmitter<void>();
  
  private orderGroupService = inject(OrderGroupService);
  
  orderGroups: IOrderGroup[] = []
  selectedIndex: number | null = null;
  selectedRowIndex: number = -1;
  name: string = '';
  position: number = 0;
  limit: number = 25;

  totalItems: number = 0;
  totalPages: number = 0;
  isLastPage: boolean = false;

  ngOnInit(): void {
    this.loadOrderGroups();
  }

  loadOrderGroups(): void {
    this.orderGroupService
      .getAll(this.position, this.limit, this.name)
      .subscribe(response => {
        this.orderGroups = response.items;
        this.totalItems = response.totalItems;
        this.totalPages = Math.ceil(this.totalItems / this.limit);
        this.isLastPage = (this.position + 1) * this.limit >= this.totalPages;
      }, error => {
        console.error(`Failed to load data`, error);
      });
  }
  
  selectAndAcceptItem(index: number): void {
    this.selectItem(index);
    this.acceptSelection();
  }

  selectRow(index: number): void {
    if (this.selectedRowIndex === index) {
      this.selectedRowIndex = -1;
    } else {
      this.selectedRowIndex = index;
    }
  }

  closePopup(): void {
    this.isVisible = false;
    this.close.emit();
  }

  closeAndShowAll(): void {
    this.selectionAccepted.emit(undefined);
    this.closePopup();
  }

  applyFilter(): void {
    this.loadOrderGroups();
  }

  nextPage(): void {
    if (!this.isLastPage) {
      this.position++;
      this.loadOrderGroups();
    }
  }

  previousPage(): void {
    if (this.position > 0) {
      this.position--;
      this.loadOrderGroups();
    }
  }

  goToPage(page: number) {
    this.position = page - 1;
    this.loadOrderGroups();
  }

  private selectItem(index: number): void {
    if (this.selectedIndex !== null) {
      this.orderGroups[this.selectedIndex].isSelected = false;
    }
    this.orderGroups[index].isSelected = true;
    this.selectedIndex = index;
  }

  private acceptSelection(): void {
    const selectedItem = this.selectedIndex !== null ? this.orderGroups[this.selectedIndex] : null;
    this.selectionAccepted.emit(selectedItem?.id);
    this.closePopup();
  }
}