import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { URLHelper } from "../helpers/url.helper";
import { ToastService } from "./toast.service";
import { IOrderGroupRequest } from "../interfaces/requests/order-group-request.interface";
import { IPaginatedOrderGroup } from "../interfaces/order-group.interface";
import { Observable, tap } from "rxjs";
import { IOrderResponse } from "../interfaces/responses/order-resopnse.interface";

@Injectable({
  providedIn: 'root'
})
export class OrderGroupService {
  private http = inject(HttpClient);
  private urlHelper = inject(URLHelper);
  private toastService = inject(ToastService);

  save(orderGroup: IOrderGroupRequest): Observable<void> {
    return this.http.post<void>(
        this.urlHelper.AddOrderGroup,
        { name: orderGroup.name, orderIds: orderGroup.orderIds }
      ).pipe(
        tap(() => this.toastService.successToast('Group has beensaved.'))
      );
  }

  getAll(position: number, limit: number, name: string): Observable<IPaginatedOrderGroup> {
    return this.http.get<IPaginatedOrderGroup>(
      this.urlHelper.GetAllOrderGroups,
      { params: {
        positon: position,
        limit: limit,
        name: name
      }});
  }

  getById(orderGroupId: string): Observable<IOrderResponse[]> {
    return this.http.get<IOrderResponse[]>(
      this.urlHelper.GetById
        .replace('{orderGroupId}', orderGroupId));
  }
}