import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { VendorService } from '../../services/vendor.service';
import { VendorInfoInterface } from '../../interfaces/vendor-info.interface';
import { RouterModule } from '@angular/router';
import { DetailsComponent } from "./details/details.component";

@Component({
    selector: 'app-vendors',
    standalone: true,
    templateUrl: './vendors.component.html',
    styleUrl: './vendors.component.sass',
    imports: [
        CommonModule,
        RouterModule,
        DetailsComponent
    ]
})
export class VendorsComponent {
  headers = ['#', 'Name', 'Komsa Code', 'Last Request', 'Dispatch Type', 'File Type', 'Price List'];
  dataList: VendorInfoInterface[] = [];

  constructor(private vendorService: VendorService) {
    this.vendorService
      .getVendorInfoList()
      .subscribe(response => {
        this.dataList = response;
      },
      error => {
        console.error('Error fetching data: ', error);
      });
   }

   isNullOrEmpty(item: VendorInfoInterface): boolean {
    return !item
      || !item.priceList
   }
    
}
