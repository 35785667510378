import { HttpClient, HttpEvent, HttpEventType } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { URLHelper } from "../helpers/url.helper";
import { Observable, finalize, map, tap } from "rxjs";
import { ToastService } from "./toast.service";
import { IPaginatedOrderResonse } from "../interfaces/responses/order-resopnse.interface";
import { IOrderLineRequest } from "../interfaces/requests/order-request.interface";
import { IOrderFilter } from "../interfaces/filters.interface";
import { ErrorHandlingService } from "./shared/error-handling.service";

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  private http = inject(HttpClient);
  private urlHelper = inject(URLHelper);
  private toastService = inject(ToastService);
  private errorHandler = inject(ErrorHandlingService);

  getOrders(position: number, limit: number, filters: IOrderFilter): Observable<IPaginatedOrderResonse> {
    var fromDateString = filters.fromDate?.getDate() + '-' + (filters.fromDate ? filters.fromDate?.getMonth() + 1: '') + '-' + filters.fromDate?.getFullYear();
    var toDateString = filters.toDate?.getDate() + '-' + (filters.toDate ? filters.toDate?.getMonth() + 1 : '') + '-' + filters.toDate?.getFullYear();

    return this.http.get<IPaginatedOrderResonse>(
      this.urlHelper.GetOrders,
      { params: {
        position: position,
        limit: limit,
        orderNumber: filters.orderNumber,
        fromDate: filters.fromDate ? fromDateString : '',
        toDate: filters.toDate ? toDateString : '',
        buyerName: filters.buyerName,
        buyerILN: filters.buyerILN,
        deliveryILN: filters.deliveryILN
      }});
  }

  getOrdersByGroupId(orderGroupId: string, filters: IOrderFilter): Observable<IPaginatedOrderResonse> {
    var fromDateString = filters.fromDate?.getDate() + '-' + (filters.fromDate ? filters.fromDate?.getMonth() + 1: '') + '-' + filters.fromDate?.getFullYear();
    var toDateString = filters.toDate?.getDate() + '-' + (filters.toDate ? filters.toDate?.getMonth() + 1 : '') + '-' + filters.toDate?.getFullYear();

    return this.http.get<IPaginatedOrderResonse>(
      this.urlHelper.GetOrdersByGroupId,
      { params: {
        orderGroupId: orderGroupId,
        orderNumber: filters.orderNumber,
        fromDate: filters.fromDate ? fromDateString : '',
        toDate: filters.toDate ? toDateString : '',
        buyerName: filters.buyerName,
        buyerILN: filters.buyerILN,
        deliveryILN: filters.deliveryILN
      }});
  }

  updateOrderLine(orderId: string, orderLineId: string, updatedOrderLine: IOrderLineRequest): Observable<any> {
    return this.http.put(
      this.urlHelper.UpdateOrderLine
        .replace('{orderId}', orderId)
        .replace('{orderLineId}', orderLineId),
      { ean: updatedOrderLine.ean,
        code: updatedOrderLine.code,
        quantity: updatedOrderLine.quantity,
        orderedUnitPrice: updatedOrderLine.orderedUnitPrice })
        .pipe(
          tap(() => this.toastService.successToast("Order line has been successfully updated."))
        );
  }

  updateItemLineUnitPrice(ean: string, price: number): Observable<any> {
    return this.http.put(
      this.urlHelper.UpdateOrderLineUnitPrice,
      { unitPrice: price, ean: ean })
      .pipe(
        tap(() => this.toastService.successToast("Unit price has been successfully updated."))
      );
  }

  uploadOrderFiles(files: File[]): Observable<number> {
    const formData = new FormData();

    files.forEach(file => {
      formData.append('files', file, file.name);
    });

    return this.http.post(
      this.urlHelper.UploadOrderFiles,
      formData, {
        reportProgress: true,
        observe: 'events'
      }).pipe(
        map((event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              return Math.round((100 * event.loaded) / (event.total || 1));
            case HttpEventType.Response:
              return 100;
            default: 
              return 0;
          }
        }),
        finalize(() => {
          if (!this.errorHandler.hasErrorOccurred()) {
            this.toastService.successToast('Files has been successfully uploaded.');
          }

          this.errorHandler.resetErrorFlag();
        })
      );
  }

  exportToXml(orderId: string): Observable<{blob: Blob, fileName: string}> {
    return this.http.get(
        this.urlHelper.ExportToXml,
        { params: { orderId: orderId },
          observe: 'response',
          responseType: 'blob'
        }).pipe(
          map(response => {
            const fileName = response.headers
            .get('content-disposition')
            ?.split(';')[1]
            .split('=')[1]
            .replaceAll('"', '') ?? '';

            const blob: Blob = response.body as Blob;
            return {blob, fileName };
          }),
          tap(() => this.toastService.successToast("Xml file has been successfully generated."))
        );
  }
}