import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { URLHelper } from "../../helpers/url.helper";
import { Observable } from "rxjs";
import { IMaterialMovement } from "../../interfaces/vmi/material-movement.interface";
import { IPaginatedResponse } from "../../interfaces/shared/paginated-response.interface";
import { IPagination } from "../../interfaces/shared/pagination.interface";
import { TypeOfMovement } from "../../enums/type-of-movement.enum";
import { ToastService } from "../toast.service";

@Injectable()
export class MaterialMovementService {
  private http = inject(HttpClient);
  private urlHelper = inject(URLHelper);
  private toastService = inject(ToastService);
  
  getMaterialMovements(
    documentNumberFilter: string,
    customerServicePointFilter: string,
    supplierProductCodeFilter: string,
    deliveryDocumentNumberFilter: string,
    serialNumberFilter: string,
    pagination: IPagination,
    typeOfMovement: TypeOfMovement
  ): Observable<IPaginatedResponse<IMaterialMovement>> {
    const params = {
      documentNumber: documentNumberFilter,
      customerServicePoint: customerServicePointFilter,
      supplierProductCode: supplierProductCodeFilter,
      deliveryDocumentNumber: deliveryDocumentNumberFilter,
      serialNumber: serialNumberFilter,
      page: pagination.page.toString(),
      pageSize: pagination.pageSize.toString(),
      typeOfMovement: typeOfMovement.toString()
    };

    return this.http
      .get<IPaginatedResponse<IMaterialMovement>>(
        this.urlHelper.GetMateriaMovements, { params });
  }

  downloadFilteredExcel(
    selectedType: TypeOfMovement,
    startDate: string,
    endDate: string,
    documentNumber: string,
    customerServicePoint: string,
    supplierProductCode: string,
    deliveryDocumentNumber: string,
    serialNumber: string
  ): void {
    this.http.post(
      this.urlHelper.DownloadMaterialMovements,
      {
        selectedType,
        startDate,
        endDate,
        documentNumber,
        customerServicePoint,
        supplierProductCode,
        deliveryDocumentNumber,
        serialNumber
      },
      { responseType: 'blob' }
    ).subscribe({
        next: (blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = "MaterialMovements.xlsx";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        },
        error: (error: any) => {
          console.error("Failed to download Excel file:", error);
        }
      })
  }
}