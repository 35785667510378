import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

export const TOAST_STATE = {
  success: 'success-toast',
  warning: 'warning-toast',
  danger: 'danger-toast'
}

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  public showsToast$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public toastMessage$: BehaviorSubject<string> = new BehaviorSubject<string>('Default Toast Message');
  public toastState$: BehaviorSubject<string> = new BehaviorSubject<string>(TOAST_STATE.success);

  successToast(toastMessage: string): void {
    this.showToast(
      TOAST_STATE.success,
      toastMessage);

    setTimeout(() => {
      this.dismissToast();
    }, 2000);
  }

  warningToast(toastMessage: string): void {
    this.showToast(
      TOAST_STATE.warning,
      toastMessage);

    setTimeout(() => {
      this.dismissToast();
    }, 2000);
  }

  dangerToast(toastMessage: string): void {
    this.showToast(
      TOAST_STATE.danger,
      this.trimMiddle(toastMessage));

    setTimeout(() => {
      this.dismissToast();
    }, 2000);
  }

  showToast(toastState: string, toastMessage: string) : void {
    this.toastState$.next(toastState);
    this.toastMessage$.next(toastMessage);
    this.showsToast$.next(true);
  }

  dismissToast(): void {
    this.showsToast$.next(false);
  }

  private trimMiddle(text: string, maxLength: number = 50): string {
    if (text.length <= maxLength) {
      return text;
    }

    const start = text.slice(0, 30);
    const end = text.slice(-14);

    return `${start} ... ${end}`;
  }
}