<div class="container">
  <div class="row justify-content-center">
    <div class="col-md-6">
      <div class="text-center mt-5">
        <h1 class="display-4">404 - Page Not Found</h1>
        <p class="lead">The page you are looking for does not exist.</p>
        <a [routerLink]="['']" class="btn btn-primary">Go to Home</a>
      </div>
    </div>
  </div>
</div>