<div class="container mt-5">
  <div class="container-flex">
    <a class="plus-sign" [routerLink]="['add']"></a>
    <span class="vendors">Vendors</span>
  </div>
  <table class="table table-bordered table-striped">
    <thead>
      <tr>
        <th *ngFor="let header of headers" scope="col">{{ header }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of dataList; let i = index"
        [ngClass]="{'red-text': isNullOrEmpty(item)}"
        [routerLink]="[item.id]">
          <td>{{ i+1 }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.komsaCode }}</td>
          <td>{{ item.lastRequest| date:'yyyy-MM-dd HH:mm:ss' }}</td>
          <td>{{ item.dispatchType }}</td>
          <td>{{ item.fileType }}</td>
          <td>{{ item.priceList }}</td>
        </tr>
      </tbody>
  </table>
</div>