import { ChangeDetectorRef, Component, OnInit, inject } from '@angular/core';
import { OrderFileUploadComponent } from "./order-file-upload/order-file-upload.component";
import { CommonModule } from '@angular/common';
import { OrderService } from '../../services/order.service';
import { IOrderLineResponse, IOrderResponse } from '../../interfaces/responses/order-resopnse.interface';
import { FormsModule } from '@angular/forms';
import { EditPriceModalComponent } from "./edit-price-modal/edit-price-modal.component";
import { IGroupedStatistics, StatisticsNavbarComponent } from "./statistics-navbar/statistics-navbar.component";
import { FileSaverService } from '../../services/file-saver.service';
import { OrderGroupService } from '../../services/order-group.service';
import { IOrderGroupRequest } from '../../interfaces/requests/order-group-request.interface';
import { SetGroupNameComponent } from "./set-group-name/set-group-name.component";
import { GroupsComponent } from "./groups/groups.component";
import { IOrderFilter } from '../../interfaces/filters.interface';
import { DualDatePickerComponent } from "../shared/dual-date-picker/dual-date-picker.component";
import { SetExportCsvNameComponent } from './set-export-csv-name/set-export-csv-name.component';
import { BooleanToYesNoPipe } from "../../pipes/boolean-to-yes-no.pipe";
import { saveAs } from 'file-saver';

@Component({
    selector: 'app-orders',
    standalone: true,
    templateUrl: './orders.component.html',
    styleUrl: './orders.component.sass',
    imports: [
    OrderFileUploadComponent,
    CommonModule,
    FormsModule,
    EditPriceModalComponent,
    StatisticsNavbarComponent,
    SetGroupNameComponent,
    SetExportCsvNameComponent,
    GroupsComponent,
    DualDatePickerComponent,
    BooleanToYesNoPipe
]
})
export class OrdersComponent implements OnInit {
  private orderService = inject(OrderService);
  private fileSaverService = inject(FileSaverService);
  private orderGroupService = inject(OrderGroupService);
  private cdr = inject(ChangeDetectorRef);
  
  orders: IOrderResponse[] = [] 
  selectedOrders: IOrderResponse[] = [];

  totalQuantity: number = 0;
  averageUnitPrice: number = 0;
  groupedStatistics: IGroupedStatistics[] = [];

  position: number = 0;
  limit: number = 25;
  totalItems: number = 0;
  totalPages: number = 0;
  isLoading: boolean = false;
  isLastPage: boolean = false;

  editingOrderLine!: IOrderLineResponse;
  showModal: boolean = false;
  setGroupName: boolean = false;
  isGroupsVisible: boolean = false;
  setCsvFileName: boolean = false;
  selectedGroupId: string | undefined;

  orderFilter: IOrderFilter = {
    orderNumber: '',
    fromDate: null,
    toDate: null,
    buyerName: '',
    buyerILN: '',
    deliveryILN: ''};
  
  ngOnInit(): void {
    this.loadOrders();
  }

  loadOrders(): void {
    if (!this.selectedGroupId) {
      this.orderService
        .getOrders(this.position, this.limit, this.orderFilter)
        .subscribe(data => {
          this.orders = data.items;
          this.totalItems = data.totalItems;
          this.totalPages = Math.ceil(this.totalItems / this.limit);
          this.isLoading = false;
          this.isLastPage = (this.position + 1) * this.limit >= this.totalItems;
        }, error => {
          console.error('Failed to load data', error);
          this.isLoading = false;
        });
    } else {
      this.orderService
        .getOrdersByGroupId(this.selectedGroupId, this.orderFilter)
        .subscribe(data => {
          this.orders = data.items;
          this.totalItems = data.totalItems;
          this.totalPages = Math.ceil(this.totalItems / this.limit);
          this.isLoading = false;
          this.isLastPage = (this.position + 1) * this.limit >= this.totalItems;
        });
    }
  }

  toggleDetails(index: number): void {
    this.orders[index].showDetails = !this.orders[index].showDetails;
  }

  toggleSelection(order: IOrderResponse): void {
    order.isSelected =!order.isSelected;

    if (order.isSelected) {
      this.selectedOrders.push(order);
    } else {
      this.selectedOrders = this.selectedOrders.filter(selectedOrder => selectedOrder.id !== order.id);
    }

    this.calculateStatistics();
    this.cdr.detectChanges();
  }

  toggleAllSelection(event: Event): void {
    const isAllSelected = this.selectedOrders.length === this.orders.length;

    if (isAllSelected) {
      this.selectedOrders = [];
      this.orders.forEach(order => order.isSelected = false);
    } else {
      this.selectedOrders = [...this.orders];
      this.orders.forEach(order => order.isSelected = true);
    }

    event.stopPropagation();
    this.calculateStatistics();
    this.cdr.detectChanges();
  }

  calculateStatistics(): void {
    let totalQuantity = 0;
    let totalPrice = 0;
    let codeMap: { [key: string]: { ean: string, name: string, totalQuantity: number, totalUnitPrice: number, count: number } } = {};

    this.selectedOrders.forEach(order => {
      order.orderLines.forEach(orderLine => {
        totalQuantity += orderLine.quantity;
        totalPrice += orderLine.orderedUnitPrice;
        
        if (!codeMap[orderLine.code]) {
          codeMap[orderLine.code] = { ean: orderLine.ean, name: orderLine.name, totalQuantity: 0, totalUnitPrice: 0, count: 0 };
        }
        codeMap[orderLine.code].totalQuantity += orderLine.quantity;
        codeMap[orderLine.code].totalUnitPrice += orderLine.orderedUnitPrice;
        codeMap[orderLine.code].count++;
      });
    });

    this.totalQuantity = totalQuantity;
    this.averageUnitPrice = this.selectedOrders.length > 0 ? totalPrice / this.selectedOrders.length : 0;

    this.groupedStatistics = Object.keys(codeMap).map(code => ({
      code,
      ean: codeMap[code].ean,
      name: codeMap[code].name,
      totalQuantity: codeMap[code].totalQuantity,
      averageUnitPrice: codeMap[code].totalUnitPrice / codeMap[code].count
    }));
  }

  paginatedItems(): void {
    const start = this.position * this.limit;
    const end = start + this.limit;
  }

  nextPage(): void {
    if (!this.isLastPage) {
      this.position++;
      this.loadOrders();
    }
  }

  previousPage(): void {
    if (this.position > 0) {
      this.position--;
      this.loadOrders();
    }
  }

  goToPage(page: number) {
    this.position = page - 1;
    this.loadOrders();
  }

  updatePage(): void {
    this.position = 0;
    this.loadOrders();
  }

  exportOrders(): void {
    this.setCsvFileName = true;
  }

  saveGroup(name: string): void {
    let orderIds: string[] = [];

    this.selectedOrders.forEach(o => orderIds.push(o.id));

    const orderGroup: IOrderGroupRequest = {
      name: name,
      orderIds: orderIds
    };

    this.orderGroupService
      .save(orderGroup)
      .subscribe();
  }

  exportOrdersToCsv(name: string): void {
    this.fileSaverService
      .exportOrders(this.selectedOrders, name);
  }

  getRowClass(order: IOrderResponse): string {
    let allLess = true;
    let anyMore = false;
    
    for (let orderLine of order.orderLines) {
      const totalStock = orderLine.warehouse.komsaStock + orderLine.warehouse.projectStock + orderLine.warehouse.lenovoStock;
      
      if (orderLine.quantity > totalStock) {
        anyMore = true;
      }
      if (orderLine.quantity <= totalStock) {
        allLess = false;
      }
    }

    if (allLess) {
      return 'table-danger';
    } else if (anyMore) {
      return 'table-warning';
    } else {
      return 'table-success';
    }
  }

  getOrderLineClass(orderLine: IOrderLineResponse): string {
    const totalStock = orderLine.warehouse.komsaStock + orderLine.warehouse.projectStock + orderLine.warehouse.lenovoStock;

    if (orderLine.quantity > totalStock) {
      return 'table-danger';
    } else {
      return 'table-success';
    }
  }

  openEditPriceModal(orderLine: IOrderLineResponse): void {
    this.editingOrderLine = orderLine;
    this.showModal = true;
  }

  setOrderGroupName(): void {
    this.setGroupName = true;
  }

  saveUnitPrice(newPrice: number): void {
    const selectedOrders = this.orders.filter(order => order.isSelected)
    
    if (selectedOrders) {
      selectedOrders.forEach(order => {
        order.orderLines.forEach(orderLine => {
          if (!orderLine.isCompleted && orderLine.ean === this.editingOrderLine.ean) {
            this.orderService
              .updateOrderLine(
                order.id,
                orderLine.id,
                { ean: orderLine.ean,
                  code: orderLine.code,
                  quantity: orderLine.quantity,
                  orderedUnitPrice: newPrice,
                })
              .subscribe(() => {
                this.orders.forEach(o =>
                  o.orderLines.forEach(ol => {
                    if (!ol.isCompleted && ol.ean === this.editingOrderLine.ean) {
                      ol.orderedUnitPrice = newPrice;
                      this.updateGrouppedStatisticsPrice(this.editingOrderLine.ean, newPrice);
                    }
                  }));
                
                this.showModal = false;
              });
          }
        });
      });
    } else if (this.editingOrderLine) {
      this.orderService
        .updateItemLineUnitPrice(this.editingOrderLine.ean, newPrice)
        .subscribe(() => {
          this.orders.forEach(o =>
            o.orderLines.forEach(ol => {
              if (!ol.isCompleted && ol.ean === this.editingOrderLine.ean) {
                ol.orderedUnitPrice = newPrice;
                this.updateGrouppedStatisticsPrice(this.editingOrderLine.ean, newPrice);
              }
            }));
          
          this.showModal = false;
        })
    }
  }

  orderGroupSelection(orderGroupId: string | undefined) {
    this.selectedGroupId = orderGroupId;
  }
  
  closeModal(): void {
    this.showModal = false;
  }

  closeSetGroupName(): void {
    this.setGroupName = false;
  }

  closeSetExportCsvName(): void {
    this.setCsvFileName = false;
  }

  showGroups(): void {
    this.isGroupsVisible = true;
  }

  closeGroups(): void {
    this.loadOrders();
    this.isGroupsVisible = false;
  }
  
  onDateChange(event: { fromDate: string | null, toDate: string | null }): void {
    this.orderFilter.fromDate = event.fromDate ? new Date(event.fromDate) : null;
    this.orderFilter.toDate = event.toDate ? new Date(event.toDate) : null;
    this.applyFilter();
  }

  applyFilter(): void {
    this.loadOrders();
  }
  
  exportOrdersToXml(): void {
    this.selectedOrders.forEach(o => 
      this.orderService.exportToXml(o.id)
        .subscribe(({ blob, fileName }) => {
          saveAs(blob, fileName);
        }));
  }

  private updateGrouppedStatisticsPrice(ean: string, newPrice: number): void {
    this.groupedStatistics
      .filter(x => x.ean === ean)
      .forEach(x => x.averageUnitPrice = newPrice)
  }
}