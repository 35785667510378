import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { JWTTokenService } from "../services/jwt-token.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(
    private router: Router,
    private jwtTokenService: JWTTokenService) {}

  canActivate: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
    return this.checkAuthentication();
  }

  private checkAuthentication(): boolean | UrlTree {
    const role = this.jwtTokenService.getRole();

    if (this.jwtTokenService.getUser()) {
      if (this.jwtTokenService.isTokenExpired()) {
        return this.router.createUrlTree(['sign-in']);
      }
      return true;
    } else {
        return this.router.createUrlTree(['sign-in']);
    }
  }
}