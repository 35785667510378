import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { TypeOfMovement } from '../../../enums/type-of-movement.enum';
import { IPagination, Pagination } from '../../../interfaces/shared/pagination.interface';
import { MaterialMovementService } from '../../../services/vmi/material-movement.service';
import { IPaginatedResponse } from '../../../interfaces/shared/paginated-response.interface';
import { IMaterialMovement } from '../../../interfaces/vmi/material-movement.interface';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-material-movements',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule
  ],
  templateUrl: './material-movements.component.html',
  styleUrl: './material-movements.component.sass'
})
export class MaterialMovementsComponent implements OnInit{
  private materialMovementService = inject(MaterialMovementService);
  
  selectedType: TypeOfMovement = TypeOfMovement.SaleReceipt; // Set a default value
  
  documentNumberFilter: string = '';
  customerServicePointFilter: string = '';
  supplierProductCodeFilter: string = '';
  deliveryDocumentNumberFilter: string = '';
  serialNumberFilter: string = '';

  startDate: string;
  endDate: string;

  typeOfMovement: TypeOfMovement[] = Object.values(TypeOfMovement);
  metadata: IPagination = new Pagination();
  data: IMaterialMovement[] = [];
  pageSizeOptions = [50, 100, 150, 200];
  customPageInput: number = 1;

  constructor() {
    const today = new Date();
    const pastDate = new Date();
    pastDate.setDate(today.getDate() - 31);

    this.startDate = pastDate.toISOString().split('T')[0];
    this.endDate = today.toISOString().split('T')[0];
  }
  
  ngOnInit(): void {
    this.fetchData();
  }

  fetchData(): void {
    this.materialMovementService
      .getMaterialMovements(
        this.documentNumberFilter,
        this.customerServicePointFilter,
        this.supplierProductCodeFilter,
        this.deliveryDocumentNumberFilter,
        this.serialNumberFilter,
        this.metadata,
        this.selectedType)
      .subscribe((response: IPaginatedResponse<IMaterialMovement>) => {
          this.data = response.result;
          this.metadata = response.metadata;
        });
  }

  exportToExcel(): void {
    this.materialMovementService.downloadFilteredExcel(
      this.selectedType,
      this.startDate,
      this.endDate,
      this.documentNumberFilter,
      this.customerServicePointFilter,
      this.supplierProductCodeFilter,
      this.deliveryDocumentNumberFilter,
      this.serialNumberFilter);
  }

  onPageChange(page: number): void {
    if (page >= 1 && page <= this.metadata.totalPages) {
      this.metadata.page = page;
      this.customPageInput = page;
      this.fetchData();
    }
  }

  selectTab(type: TypeOfMovement): void {
    this.selectedType = type; // Update the selected type
    this.metadata.page = 1; // Reset to fitst page when changing tab
    this.fetchData();
  }
  
  onFilterChange(): void {
    this.fetchData();
  }
  
  setPageSize(size: number) : void {
    this.metadata.pageSize = size;
    this.metadata.page = 1;
    this.fetchData();
  }

  jumpToPage(): void {
    const page = Number(this.customPageInput);
    if (page >= 1 && page <= this.metadata.totalPages) {
      this.onPageChange(page);
    }
  }
}
