import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { URLHelper } from "../helpers/url.helper";
import { IProductSelectionRequest } from "../interfaces/requests/product-selection-request.interface";
import { Observable, tap } from "rxjs";
import { IProductSelectionResponse } from "../interfaces/responses/product-selection-response.interface";
import { ToastService } from "./toast.service";

@Injectable()
export class ProductSelectionService {
  private http = inject(HttpClient);
  private urlHelper = inject(URLHelper);
  private toastService = inject(ToastService);

  getProductSelection(vendorId: string): Observable<IProductSelectionResponse> {
    return this.http
      .get<IProductSelectionResponse>(
        this.urlHelper.GetProductSelection
          .replace('{id}', vendorId))
  }

  updateProductSelection(catalog: IProductSelectionRequest, vendorId: string): Observable<void> {
    return this.http.post<void>(
        this.urlHelper.UpdateProductSelection
          .replace('{id}', vendorId),
        catalog
      ).pipe(
        tap(() => this.toastService.successToast('Product setting updated successfully.'))
      );
  }

  removeWarehouseSetting(vendorId: string, warehouseId: string): Observable<void> {
    return this.http.delete<void>(
      this.urlHelper.RemoveWarehouseSetting
        .replace('{vendorId}', vendorId)
        .replace('{warehouseId}', warehouseId)
      ).pipe(
        tap(() => this.toastService.successToast('Warehouse removed successfully.'))
      );
  }

  removeProductGroupSetting(vendorId: string, productGroupId: string): Observable<void> {
    return this.http.delete<void>(
      this.urlHelper.RemoveProductGroupsSetting
        .replace('{vendorId}', vendorId)
        .replace('{productGroupId}', productGroupId)
      ).pipe(
        tap(() => this.toastService.successToast('Product Group removed successfully.'))
      );
  }

  removeProductSetting(vendorId: string, productId: string): Observable<void> {
    return this.http.delete<void>(
      this.urlHelper.RemoveProductsSetting
        .replace('{vendorId}', vendorId)
        .replace('{productId}', productId)
      ).pipe(
        tap(() => this.toastService.successToast('Product removed successfully.'))
      );
  }
}