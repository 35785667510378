import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-set-group-name',
  standalone: true,
  imports: [
    FormsModule
  ],
  templateUrl: './set-group-name.component.html',
  styleUrl: './set-group-name.component.sass'
})
export class SetGroupNameComponent {
  @Input() show: boolean = false;
  @Output() save = new EventEmitter<string>();
  @Output() close = new EventEmitter<void>();

  name: string = '';

  onSave(): void {
    this.save.emit(this.name);
    this.onClose()
  }

  onClose(): void {
    this.show = false;
    this.close.emit();
  }
}
