import { Component, Input, OnInit, inject } from '@angular/core';
import { DownloadProductsService } from '../../../services/download-products.service';
import saveAs from 'file-saver';

@Component({
  selector: 'app-products',
  standalone: true,
  imports: [],
  templateUrl: './products.component.html',
  styleUrl: './products.component.sass'
})
export class ProductsComponent implements OnInit {
  private downloadProductsService = inject(DownloadProductsService);
  private vendorBaseLink: string = '';
  private name: string = '';

  url: string = '';
  
  @Input()
  set id(vendorBaseLink: string) {
    this.vendorBaseLink = vendorBaseLink;
  }

  @Input()
  set fileName(name : string) {
    this.name = name;
  }

  ngOnInit(): void {
    this.downloadProductsService
      .downloadProduct(
        this.vendorBaseLink,
        this.name)
      .subscribe(data => {
        this.url = data.url;
        saveAs(data.blob, this.name);
      });
  }
}
