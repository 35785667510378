import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

@Component({
  selector: 'app-receivers-form-child',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    FormsModule],
  templateUrl: './receivers-form-child.component.html',
  styleUrl: './receivers-form-child.component.sass'
})
export class ReceiversFormChildComponent implements OnInit {
  fb = inject(FormBuilder);

  receiversGroup: FormGroup = this.fb.group({
    receivers: this.fb.array([])
  });

  @Input() formData!: FormGroup;
  @Output() onFormGroupChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  ngOnInit(): void {
    this.addField();
    this.addGroupToParent();
  }
  
  get receiverArray(): FormArray {
    return this.receiversGroup.get('receivers') as FormArray;
  }
  
  addGroupToParent(): void {
    this.formData.addControl('receiverGroup', this.receiversGroup);
  }

  addField(): void {
    this.receiverArray.push(this.fb.control('', Validators.required));
  }

  receiver(index: number): FormControl {
    const receivers = this.receiversGroup.get('receivers') as FormArray;
    return receivers.at(index) as FormControl;
  }

  removeField(index: number): void {
    (this.receiversGroup.get('receivers') as FormArray).removeAt(index);
  }

}
