<div class="home-container">
  <div class="sidebar-container">
    <app-sidebar></app-sidebar>
  </div>

  <main class="content-container">
    <div class="content-inner">
      <router-outlet></router-outlet>
    </div>
  </main>
</div>

