import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { OrderService } from '../../../services/order.service';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-order-file-upload',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink
  ],
  templateUrl: './order-file-upload.component.html',
  styleUrl: './order-file-upload.component.sass'
})
export class OrderFileUploadComponent {
  private orderService = inject(OrderService);

  errorMessage: string | null = null;
  uploadProgress = 0;
  selectedFiles: File[] = [];

  onFilesSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      this.selectedFiles = Array.from(input.files);
    }
  }

  removeFile(index: number): void {
    this.selectedFiles.splice(index, 1);
  }

  uploadFiles(): void {
    if (this.selectedFiles.length > 0) {
      this.orderService.uploadOrderFiles(this.selectedFiles)
      .subscribe({
        next: (data) => {
          this.uploadProgress = data;
        },
        error: (error) => {
          this.errorMessage = error;
        }
      });
        // .subscribe(progress => {
        //   this.uploadProgress = progress;
        // });
        
      this.selectedFiles = [];
    }
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    if (event.dataTransfer?.files) {
      this.selectedFiles = Array.from(event.dataTransfer.files);
    }
  }
}
