import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

export interface IGroupedStatistics {
  code: string;
  ean: string;
  name: string;
  totalQuantity: number;
  averageUnitPrice: number;
}

@Component({
  selector: 'app-statistics-navbar',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './statistics-navbar.component.html',
  styleUrl: './statistics-navbar.component.sass'
})
export class StatisticsNavbarComponent {
  @Input() totalOrdersSelected: number = 0;
  @Input() totalQuantity: number = 0;
  @Input() averageUnitPrice: number = 0;
  @Input() groupedStatistics: IGroupedStatistics[] = [];
  
  showDetails: boolean = false;

  toggleDetails(): void {
    this.showDetails = !this.showDetails;
  }
}
