import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ToastService } from '../../../services/toast.service';

@Component({
  selector: 'app-toast',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.sass',
  animations: [
    trigger('toastTrigger', [
      state('open', style({ transform: 'translateY(0%)' })),
      state('close', style({ transform: 'translateY(-200%' })),
      transition('open <=> close', [
        animate('300ms ease-in-out')
      ])
    ])
  ]
})

export class ToastComponent {
  toastClass: string[] = [];
  toastMessage: string = '';
  showsToast: boolean = false;

  constructor(public toast: ToastService) {}
}
