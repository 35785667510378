import { Injectable } from "@angular/core";
import { JWTTokenService } from "../services/jwt-token.service";
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class VmiGuard {
  private role: string | null;

  constructor(private jwtTokenService: JWTTokenService) {
    this.role = jwtTokenService.getRole();
  }

  canActivate: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
    if (!this.jwtTokenService.isTokenExpired()) {
      if (this.role?.toLowerCase() === 'superadmin') {
        return true;
      }

      if (this.role?.toLowerCase() === 'admin') {
        return true;
      }

      if (this.role?.toLowerCase() === 'vmiuser') {
        return true;
      }
    } 

    return false;
  }
}