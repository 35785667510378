<div class="container mt-5">
  <h2>Add Vendor</h2>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <fieldset formGroupName="vendorGroup">
      <div class="form-floating mb-3">
        <input type="text" class="form-control" id="komsaCode" placeholder="Komsa Code" formControlName="komsaCode">
        <label for="komsaCode">Komsa Code</label>
      </div>

      <div class="form-floating mb-3">
        <input type="password" class="form-control" id="secret" placeholder="Secret" formControlName="secret">
        <label for="secret">Secret</label>
      </div>

      <div class="form-floating mb-3">
        <select class="form-select" id="dispatchType" formControlName="dispatchType">
          <option value="" disabled selected>Select</option>
          <option *ngFor="let type of dispatchTypes" [value]="type">{{ type }}</option>
        </select>
        <label for="dispatchType">Dispatch Type</label>
      </div>

      <div class="form-floating mb-3">
        <select class="form-select" id="fileType" name="fileType" formControlName="fileType">
          <option value="" disabled selected>Select</option>
          <option *ngFor="let type of fileTypes" [ngValue]="type">{{ type }}</option>
        </select>
        <label for="fileType">File Type</label>
      </div>
    </fieldset>

    <app-receivers-form-child
      *ngIf="showReceiversFormChildComponent"
      [formData]="form"
      (onFormGroupChange)="onChange($event)">
    </app-receivers-form-child>

    <app-sftp-settings-form-child
      *ngIf="showSftpsettingsFormChildComponent"
      [formData]="form"
      (onFormGroupChange)="onChange($event)">
    </app-sftp-settings-form-child>
    
    <button type="submit" class="btn btn-primary">Submit</button>
    <a [routerLink]="['..']" class="btn btn-secondary">Back</a>
  </form>
</div>