import { Injectable, inject } from "@angular/core";
import { KomsaApiHttpService } from "../services/komsa-api-http.service";

@Injectable()
export class URLHelper {
  private httpService = inject(KomsaApiHttpService);
  
  private env: string = this.httpService.environment;
  private host: string = this.httpService.host;
  private server: string = this.getServerUrl(this.env);
  
  private getServerUrl(env: string): string {
    let server = '';

    switch(env) {
      case 'DEV': {
        server = 'https://localhost:5001/';
        break;
      }
      case 'PROD': {
        server = 'https://customer-backend.komsa.pl/';
        break;
      }
      case 'STAGING': {
          server = 'https://customer-backend-komsa-pl-test.komsa.net/';
          break;
      }
      default: {
        server = 'https://customer-backend.komsa.pl/';
        break;
      }
    }

    return server;
  }

  public Login: string = this.server + 'api/authentication/login';
  
  public Vendors: string = this.server + 'api/v1/vendors';
  public AddVendor: string = this.server + 'api/v1/vendors';
  public GetVendorById: string = this.server + 'api/v1/vendors/{id}';

  public ProductDownloadLinkForUser: string = this.host + '/download/products/{vendorLinkBase}/Products.{extension}';
  public ProductDownloadLink: string = this.server + 'download/products/{vendorLinkBase}/{fileName}';

  public GetWarehouses: string = this.server + 'api/v1/warehouses';

  public UpdateProductSelection: string = this.server + 'api/v1/vendors/{id}/productSettings';
  public GetProductSelection: string = this.server + 'api/v1/vendors/{id}/productSettings';
  public RemoveWarehouseSetting: string = this.server + 'api/v1/vendors/{vendorId}/productSettings/warehouses/{warehouseId}';
  public RemoveProductGroupsSetting: string = this.server + 'api/v1/vendors/{vendorId}/productSettings/productGroups/{productGroupId}';
  public RemoveProductsSetting: string = this.server + 'api/v1/vendors/{vendorId}/productSettings/products/{productId}';

  public GetProducts: string = this.server + 'api/v2/products/{vendorId}';
  public SendProductToVendor: string = this.server + 'api/v2/products/{vendorId}/send';

  public UploadOrderFiles: string = this.server + 'api/v1/orders/upload';
  public GetOrders: string = this.server + 'api/v1/orders';
  public GetOrdersByGroupId: string = this.server + 'api/v1/orders/orderGroup';
  public UpdateOrderLineUnitPrice: string = this.server + 'api/v1/orders/updateGlobalUnitPrice';
  public UpdateOrderLine: string = this.server + 'api/v1/orders/{orderId}/OrderLines/{orderLineId}';
  public ExportToXml: string = this.server + 'api/v1/orders/exportToXml';

  public GetAllOrderGroups: string = this.server + 'api/v1/orderGroups';
  public GetById: string = this.server + 'api/v1/orderGroups/{orderGroupId}';
  public AddOrderGroup: string = this.server + 'api/v1/orderGroups';

  public GetExportedOrders: string = this.server + 'api/v1/exportedOrders';

  public GetLoadedOrders: string = this.server + 'api/v1/loadedOrders';

  //#region Vmi
  public GetMateriaMovements: string = this.server + 'api/vmi/v1/materialMovements';
  public DownloadMaterialMovements: string = this.server + 'api/vmi/v1/materialMovements/download';
  
  public GetMateriaMovementRecords: string = this.server + 'api/vmi/v1/materialMovementRecords';

  public GetInventories: string = this.server + 'api/vmi/v1/inventories';
  public GetSales: string = this.server + 'api/vmi/v1/sales';
  //#endregion
}