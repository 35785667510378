<div class="container">
  <!-- Filters -->
  <div class="filters-container">
    <!-- Filter by Document Number -->
    <div class="filter-item">
      <label for="warehouse-document-filter">Warehouse Docuemnt:</label>
      <input
        id="warehouse-document-filter"
        type="text"
        [(ngModel)]="warehouseDocumentFilter"
        (input)="onFilterChange()"
        placeholder="Search Document">
    </div>

    <!-- Filter by CSP -->
    <div class="filter-item">
      <label for="csp-filter">CSP:</label>
      <input
        id="csp-filter"
        type="text"
        [(ngModel)]="customerServicePointFilter"
        (input)="onFilterChange()"
        placeholder="Search CSP">
    </div>

    <!-- Filter by Product Code -->
    <div class="filter-item">
      <label for="product-code-filter">Product Code:</label>
      <input
        id="product-code-filter"
        type="text"
        [(ngModel)]="supplierProductCodeFilter"
        (input)="onFilterChange()"
        placeholder="Search Product Code">
    </div>

    <!-- Filter by Delivery Document Number -->
    <div class="filter-item">
      <label for="delivery-document-filter">Delivery Document:</label>
      <input
        id="delivery-document-filter"
        type="text"
        [(ngModel)]="deliveryDocumentNumberFilter"
        (input)="onFilterChange()"
        placeholder="Search Delivey Document">
    </div>

    <!-- Filter by Serial Number -->
    <div class="filter-item">
      <label for="serial-number-filter">Serial Number:</label>
      <input
        id="serial-number-filter"
        type="text"
        [(ngModel)]="serialNumberFilter"
        (input)="onFilterChange()"
        placeholder="Search Serial Number">
    </div>
    
    <!-- Filter by Document Date -->
    <div class="filter-item">
      <label for="document-date-filter">Document Date:</label>
      <input
      id="document-date-filter"
      type="date"
      [(ngModel)]="documentDateFilter"
      (change)="onFilterChange()"
      placeholder="dd/mm/yyyy">
    </div>
  </div>
    

  <!-- Table -->
  <table class="data-table">
    <thead>
      <tr>
        <th>Delivery Date</th>
        <th>Warehouse Document</th>
        <th>Delivery Document Number</th>
        <th>Supplier Product Code</th>
        <th>Serial Number</th>
        <th>CSP</th>
        <th>CSP Name</th>
       </tr>
    </thead>
    <tbody>
      <tr *ngFor="let record of data">
        <td>{{ record.documentDate| date:'dd/MM/yyyy' }}</td>
        <td>{{ record.warehouseDocument }}</td>
        <td>{{ record.deliveryDocumentNumber }}</td>
        <td>{{ record.supplierProductCode }}</td>
        <td>{{ record.serialNumber }}</td>
        <td>{{ record.customerServicePoint }}</td>
        <td>{{ record.customerServicePointName }}</td>
      </tr>
    </tbody>
  </table>

  <!-- Pagination -->
  <div class="pagination-container">
    <!-- Page Navigation -->
    <div class="pagination">
      <button [disabled]="metadata.page === 1" (click)="onPageChange(metadata.page - 1)">
        Previous
      </button>
      
      <!-- Jump to Page Input -->
      <span>
        Page
        <input
          type="number"
          class="page-jump"
          [(ngModel)]="customPageInput"
          (keydown.enter)="jumpToPage()"
          [min]="1"
          [max]="metadata.totalPages"
        />
        of {{ metadata.totalPages }}
      </span>

      <button
        [disabled]="data.length < metadata.pageSize"
        (click)="onPageChange(metadata.page + 1)"
      >
        Next
      </button>
    </div>
  
    <!-- Records per Page Selection -->
    <div class="page-size-selector">
      <span>Records per page:</span>
      <button
        *ngFor="let size of pageSizeOptions"
        [class.active]="metadata.pageSize === size"
        (click)="setPageSize(size)"
      >
        {{ size }}
      </button>
    </div>
  </div>
  
</div>
