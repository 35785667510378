import { Injectable, inject } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { IAuthResponse } from "../interfaces/responses/auth-response.interface";
import { Router } from "@angular/router";
import { IAuthRequest } from "../interfaces/requests/auth-request.interface";
import { URLHelper } from "../helpers/url.helper";
import { AppCookieService } from "./app-cookie.service";
import { JWTTokenService } from "./jwt-token.service";
import { ToastService } from "./toast.service";

@Injectable()
export class AuthService {
  private http = inject(HttpClient);
  private router = inject(Router);
  private urlHelper = inject(URLHelper);
  private appCookieService = inject(AppCookieService);
  private jwtTokenService = inject(JWTTokenService);
  private toastService = inject(ToastService);

  logIn(auth: IAuthRequest): void {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded');

    const params = new HttpParams()
      .set('Login', auth.login)
      .set('Password', auth.password);

    this.http
      .post<IAuthResponse>(
        this.urlHelper.Login,
        params,
        { headers })
      .subscribe((response)=> {          
          this.appCookieService.set('token', response.accessToken)
          this.jwtTokenService.setToken(this.appCookieService.get('token') ?? '');
          this.router.navigateByUrl('/');
        }, e => this.toastService.dangerToast('Log in failed.'),
        () => this.toastService.successToast('Successfully logged in.'));
  }

  logout(): void {
    this.appCookieService.remove('token');
    this.toastService.successToast('Successfully logged out.')
  }
}