import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DualDatePickerComponent } from '../shared/dual-date-picker/dual-date-picker.component';
import { LoadedOrderService } from '../../services/loaded-order.service';
import { ILoadedOrderFilter } from '../../interfaces/filters.interface';
import { ILoadedOrderResponse } from '../../interfaces/responses/loaded-order-response.interface';
import { DocumentStatus } from '../../enums/document-status.enum';
import { BooleanToYesNoPipe } from "../../pipes/boolean-to-yes-no.pipe";

@Component({
  selector: 'app-loaded-orders',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    DualDatePickerComponent,
    BooleanToYesNoPipe
],
  templateUrl: './loaded-orders.component.html',
  styleUrl: './loaded-orders.component.sass'
})
export class LoadedOrdersComponent implements OnInit {
  private loadedOrderService = inject(LoadedOrderService);
  
  position: number = 0;
  limit: number = 25;
  totalItems: number = 0;
  totalPages: number = 0;
  isLoading: boolean = false;
  isLastPage: boolean = false;
  
  filters: ILoadedOrderFilter = {
    orderNumber: '',
    documentNumber: '',
    deliveryNote: '',
    documentStatus: DocumentStatus.All,
    isCompleted: null,
    fromDate: null,
    toDate: null,
  };

  documentStatusOptions = Object.values(DocumentStatus);

  loadedOrders: ILoadedOrderResponse[] = [];

  ngOnInit(): void {
    this.loadData();
  }
  
  nextPage(): void {
    if (!this.isLastPage) {
      this.position++;
      this.loadData();
    }
  }
  
  previousPage(): void {
    if (this.position > 0) {
      this.position--;
      this.loadData();
    }
  }
  
  goToPage(page: number): void {
    this.position = page - 1;
    this.loadData();
  }

  updatePage(): void {
    this.position = 0;
    this.loadData();
  }

  onDateChange(event: {fromDate: string | null, toDate: string | null}): void {
    this.filters.fromDate = event.fromDate ? new Date(event.fromDate) : null;
    this.filters.toDate = event.toDate ? new Date(event.toDate) : null;
    this.applyFilter();
  }
  
  applyFilter():void {
    this.loadData();
  }
  
  toggleDetails(index: number): void {
    this.loadedOrders[index].showDetails = !this.loadedOrders[index].showDetails;
  }
  
  private loadData(): void {
    this.loadedOrderService
      .getLoadedOrders(this.position, this.limit, this.filters)
      .subscribe(data => {
        this.loadedOrders = data.items;
        this.totalItems = data.totalItems;
        this.totalPages = Math.ceil(this.totalItems / this.limit);
        this.isLoading = false;
        this.isLastPage = (this.position + 1) * this.limit >= this.totalItems;
      }, error => {
        console.error('Failed to load data', error);
        this.isLoading = false;
      });
  }
  
  
}
