<div class="upload-container">
  <div class="upload-area" (dragover)="onDragOver($event)" (drop)="onDrop($event)">
    <input type="file" id="fileUpload" (change)="onFilesSelected($event)" multiple accept=".xml, .xlsx"/>
    <label for="fileUpload" class="upload-label">
      <i class="bi bi-cloud-arrow-up"></i>
      <span>Drag & Drop your XML files here or click to upload</span>
    </label>
  </div>
  <div *ngIf="selectedFiles.length > 0" class="file-info">
    <p><strong>Selected Files:</strong></p>
    <ul>
      <li *ngFor="let file of selectedFiles; let i = index">
        {{ file.name }}
        <button class="btn btn-danger btn-sm" (click)="removeFile(i)">Remove</button>
      </li>
    </ul>
    <button class="btn btn-primary" (click)="uploadFiles()" [routerLink]="['../orders']">Upload All</button>
    <div *ngIf="uploadProgress > 0" class="progress mt-3">
      <div class="progress-bar" role="progressbar" [style.width.%]="uploadProgress" attr.aria-valuenow="{{uploadProgress}}" aria-valuemin="0" aria-valuemax="100">{{uploadProgress}}%</div>
    </div>
  </div>
</div>