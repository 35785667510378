import { HttpClient, HttpParams } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { URLHelper } from "../../helpers/url.helper";
import { IMaterialMovementRecord } from "../../interfaces/vmi/material-movement-record.interface";
import { IPaginatedResponse } from "../../interfaces/shared/paginated-response.interface";
import { Observable } from "rxjs";
import { IPagination } from "../../interfaces/shared/pagination.interface";

@Injectable()
export class MaterialMovementRecordService {
  private http = inject(HttpClient);
  private urlHelper = inject(URLHelper);

  getMaterialMovementRecords(
    documentNumber: string,
    partNumber: string,
    serialNumber: string,
    received: boolean | null,
    pagination: IPagination
  ): Observable<IPaginatedResponse<IMaterialMovementRecord>> {
    let params = new HttpParams();
    if (documentNumber !== '') {
      params = params.append('documentNumber', documentNumber);
    }
    if (partNumber !== '') {
      params = params.append('partNumber', partNumber);
    }
    if (serialNumber !== '') {
      params = params.append('serialNumber', serialNumber);
    }
    if (received !== null) {
      params = params.append('isReceived', received.toString());
    }

    params = params.append('page', pagination.page.toString());
    params = params.append('pageSize', pagination.pageSize.toString());
    
    return this.http
      .get<IPaginatedResponse<IMaterialMovementRecord>>(
        this.urlHelper.GetMateriaMovementRecords, { params });
  }
}