import { Injectable } from "@angular/core";
import FileSaver from "file-saver";
import { IOrderResponse } from "../interfaces/responses/order-resopnse.interface";

@Injectable()
export class FileSaverService {
  
  exportOrders(orders: IOrderResponse[], name: string): void {
    const csvContent = 'Order Number;Code;Ean;Name;Price;Quantity\n' +
      orders.map(o => o.orderLines
          .map(ol => `${o.orderNumber};${ol.code};${ol.ean};${ol.name};${ol.orderedUnitPrice.toString().replace('.', ',')};${ol.quantity}`)
          .join('\n'))
        .join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
    
    FileSaver.saveAs(blob, `${name}.csv`);
  }
}