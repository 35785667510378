import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { Observable } from "rxjs";
import { isPlatformBrowser } from "@angular/common";
import { AppCookieService } from "../services/app-cookie.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  platformId: Object;

  constructor(
    private appCookieService: AppCookieService,
    @Inject(PLATFORM_ID) platformId: Object) {
      this.platformId = platformId;
    }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler): Observable<HttpEvent<any>> {
      if (!isPlatformBrowser(this.platformId)) {
        return next.handle(req);
      }
      
      const token = this.appCookieService.get('token');

      if (token) {
        const cloned = req.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`
          }
        });
        return next.handle(cloned);
      } else {
        return next.handle(req);
      }
    }
}