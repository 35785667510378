import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ErrorHandlingService } from "../services/shared/error-handling.service";
import { catchError, Observable } from "rxjs";

@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {
  constructor(
    private errorHandler: ErrorHandlingService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next
      .handle(req)
      .pipe(
        catchError((error: HttpErrorResponse) => {          
          return this.errorHandler.handleError(error);
        })
      );
  }
}