import { Injectable } from "@angular/core";
import { jwtDecode } from "jwt-decode";
import { TokenInterface } from "../interfaces/token.interface";

@Injectable()
export class JWTTokenService {
  jwtToken: string | undefined;
  decodedToken: TokenInterface | undefined

  setToken(token: string): void {
    if (token) {
      this.jwtToken = token;
    }
  }

  decodeToken(): void {
    if (this.jwtToken) {
      this.decodedToken = jwtDecode<TokenInterface>(this.jwtToken);
    }
  }

  getDecodeToken(): TokenInterface {
    return jwtDecode<TokenInterface>(this.jwtToken ?? "");
  }

  getUser(): string | null {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken.name : null;
  }

  getRole(): string | null {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken.role : null;
  }

  getExpiryTime(): number | null {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken.exp : null;
  }

  isTokenExpired(): boolean {
    const expiryTime: number | null = this.getExpiryTime();
    if (expiryTime) {
      return ((1000 * expiryTime) - (new Date()).getTime()) < 5000
    } else {
      return false;
    }
  }
}