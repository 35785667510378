import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { InventoryService } from '../../../services/vmi/inventory.service';
import { IPagination, Pagination } from '../../../interfaces/shared/pagination.interface';
import { EqualityType, EqualityTypeIcons } from '../../../enums/equality-type.enum';
import { IPaginatedResponse } from '../../../interfaces/shared/paginated-response.interface';
import { FormsModule } from '@angular/forms';
import { IGroupedInventory, IRecord } from '../../../interfaces/vmi/grouped-inventory.interface';

@Component({
  selector: 'app-inventory',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule
  ],
  templateUrl: './inventory.component.html',
  styleUrl: './inventory.component.sass'
})
export class InventoryComponent implements OnInit {
  private inventoryService = inject(InventoryService);

  customerServicePointFilter: string = '';
  productCodeFilter: string = '';
  quantityFilter: number | null = null;
  equalityTypeFilter: EqualityType = EqualityType.Equals;
  
  equalityTypes = Object.entries(EqualityType).map(([key, value]) => ({
    key,
    value,
    icon: EqualityTypeIcons[value as EqualityType]
  }));
  metadata: IPagination = new Pagination();
  data: IGroupedInventory[] = [];
  pageSizeOptions = [50, 100, 150, 200];
  customPageInput: number = 1;

  constructor() {}

  ngOnInit(): void {
    this.fetchData();
  }

  fetchData(): void {
    this.inventoryService
      .getInventories(
        this.metadata,
        this.customerServicePointFilter,
        this.productCodeFilter,
        this.quantityFilter,
        this.equalityTypeFilter)
      .subscribe((response: IPaginatedResponse<IGroupedInventory>) => {
        this.data = response.result;
        this.metadata = response.metadata;
      });
  }

  clearSelectedQuantity(): void {
    this.quantityFilter = null;
    this.fetchData();
  }

  clearSelectedCustomerServicePoint(): void {
    this.customerServicePointFilter = '';
    this.fetchData();
  }

  onPageChange(page: number): void {
    if (page >= 1 && page <= this.metadata.totalPages) {
      this.metadata.page = page;
      this.customPageInput = page;
      this.fetchData();
    }
  }

  onFilterChange(): void {
    this.fetchData();
  }

  toggleDetails(record: IRecord): void {
    record.expanded = !record.expanded;
  }

  setPageSize(size: number) : void {
    this.metadata.pageSize = size;
    this.metadata.page = 1;
    this.fetchData();
  }

  jumpToPage(): void {
    const page = Number(this.customPageInput);
    if (page >= 1 && page <= this.metadata.totalPages) {
      this.onPageChange(page);
    }
  }
}
