import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { URLHelper } from "../helpers/url.helper";
import { Observable } from "rxjs";
import { IWarehouseResponse } from "../interfaces/responses/warehouse-response.interface";

@Injectable()
export class WarehouseService {
  private http = inject(HttpClient);
  private urlHelper = inject(URLHelper);

  getWarehouses(): Observable<IWarehouseResponse[]> {
    return this.http
      .get<IWarehouseResponse[]>(this.urlHelper.GetWarehouses);
  }
}