export enum EqualityType {
  Equals = 'Equal',
  NotEqual = 'NotEqual',
  LessThan = 'LessThan',
  GreaterThan = 'GreaterThan',
  LessOrEqualTo = 'LessOrEqualTo',
  GreaterOrEqualTo = 'GreaterOrEqualTo'
}

// Map EqualityType to Icons
export const EqualityTypeIcons: { [key in EqualityType]: string } = {
  [EqualityType.Equals]: 'fas fa-equals',
  [EqualityType.NotEqual]: 'fas fa-not-equal',
  [EqualityType.LessThan]: 'fas fa-less-than',
  [EqualityType.GreaterThan]: 'fas fa-greater-than',
  [EqualityType.LessOrEqualTo]: 'fas fa-less-equal',
  [EqualityType.GreaterOrEqualTo]: 'fas fa-greater-equal'
}