<div class="container">
  <div class="row justify-content-center">
    <div class="col-md-8">
      <div class="card">
        <div class="card-header">
          <h2 class="card-title">{{vendor?.name}}</h2>
        </div>
        <div class="card-body">
          <div class="detail-item">
            <p><strong>Komsa Code:</strong> {{vendor?.komsaCode}}</p>
            <p><strong>Client ID:</strong> {{vendor?.clientId}}</p>
            <p><strong>XML Product Download Link:</strong> {{getDownloadLink('xml')}}</p>
            <p><strong>CSV Product Download Link:</strong> {{getDownloadLink('csv')}}</p>
            <p><a class="btn btn-primary" (click)="sendProductsToVendor()">Send Products to Vendor</a></p>
          </div>
          <h3 class="mt-4">Dispatch Settings</h3>
          <hr>
          <div *ngFor="let settings of vendor?.dispatchSettings">
            <div class="detail-item">
              <p><strong>Dispatch Type:</strong> {{settings?.dispatchType}}</p>
              <p><strong>File Type:</strong> {{settings?.fileType}}</p>
              <p *ngIf="settings?.dispatchType === 'Email'"><strong>Receivers:</strong> {{settings?.receivers}}</p>
              <div *ngFor="let sftpSetting of settings?.sftpSettings">
                <p><strong>Host:</strong> {{sftpSetting?.host}}</p>
                <p><strong>User:</strong> {{sftpSetting?.user}}</p>
                <p><strong>Port:</strong> {{sftpSetting?.port}}</p>
              </div>
            </div>
          </div>
          <div class="detail-item">
            <a class="btn btn-primary" [routerLink]="'product-selection-form'">Edit product catalog</a>
            <a class="btn btn-primary" [routerLink]="'preview-products'">Preview Products</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>