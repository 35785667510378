import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SaleService } from '../../../services/vmi/sale.service';
import { IPaginatedResponse } from '../../../interfaces/shared/paginated-response.interface';
import { ISale } from '../../../interfaces/vmi/sale.interface';
import { IPagination, Pagination } from '../../../interfaces/shared/pagination.interface';
import { response } from 'express';

@Component({
  selector: 'app-sales',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule
  ],
  templateUrl: './sales.component.html',
  styleUrl: './sales.component.sass'
})
export class SalesComponent implements OnInit {
  private saleService = inject(SaleService);

  warehouseDocumentFilter: string = '';
  customerServicePointFilter: string ='';
  supplierProductCodeFilter: string = '';
  deliveryDocumentNumberFilter: string = '';
  serialNumberFilter: string = '';
  documentDateFilter: Date | null = null;
  
  metadata: IPagination = new Pagination();
  data: ISale[] = []
  pageSizeOptions = [50, 100, 150, 200];
  customPageInput: number = 1;

  constructor() {}

  ngOnInit(): void {
    this.fetchData();
  }

  private fetchData(): void {
    this.saleService
      .getSales(
        this.warehouseDocumentFilter,
        this.customerServicePointFilter,
        this.supplierProductCodeFilter,
        this.deliveryDocumentNumberFilter,
        this.serialNumberFilter,
        this.documentDateFilter,
        this.metadata)
      .subscribe((response: IPaginatedResponse<ISale>) => {
        this.data = response.result;
        this.metadata = response.metadata;
      });
  }

  onFilterChange(): void {
    this.fetchData();
  }

  onPageChange(page: number): void {
    if (page >= 1 && page <= this.metadata.totalPages) {
      this.metadata.page = page;
      this.customPageInput = page;
      this.fetchData();
    }
  }
  
  setPageSize(size: number): void {
    this.metadata.pageSize = size;
    this.metadata.page = 1;
    this.fetchData();
  }

  jumpToPage(): void {
    const page = Number(this.customPageInput);
    if (page >= 1 && page <= this.metadata.totalPages) {
      this.onPageChange(page);
    }
  }
}
