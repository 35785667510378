export interface IPagination {
  page: number; // The current page number
  pageSize: number; // Number of records per page
  totalRecords: number; // Total number of records in the dataset
  totalPages: number; // Total number od pages
}

export class Pagination implements IPagination {
  page: number;
  pageSize: number;
  totalRecords: number;
  totalPages: number;

  constructor (
    page: number = 1,
    pageSize: number = 50,
    totalRecords: number = 0,
    totalPages: number = 1
  ) {
    this.page = page;
    this.pageSize = pageSize;
    this.totalRecords = totalRecords;
    this.totalPages = totalPages;
  }
}