<div class="container">
  <!-- Filters -->
  <div class="filters-container">
    <!-- Filter by CSP -->
    <div class="filter-item">
      <label for="csp-filter">CSP:</label>
      <input
        id="csp-filter"
        type="text"
        [(ngModel)]="customerServicePointFilter"
        (input)="onFilterChange()"
        placeholder="Search CSP">
    </div>

    <!-- Filter by Product Code -->
    <div class="filter-item">
      <label for="product-code-filter">Product Code:</label>
      <input
        id="product-code-filter"
        type="text"
        [(ngModel)]="productCodeFilter"
        (input)="onFilterChange()"
        placeholder="Search Product Code">
    </div>

    <!-- Filter by Quantity -->
    <div class="filter-item">
      <label for="quantity-filter">Quantity:</label>
      <input
        id="quantity-filter"
        type="number"
        [(ngModel)]="quantityFilter"
        (input)="onFilterChange()"
        placeholder="Enter Quantity">
    </div>

    <!-- Filter by EqualityType -->
    <div class="filter-item">
      <label for="equality-filter">Equality Type:</label>
      <select id="equality-filter" [(ngModel)]="equalityTypeFilter" (change)="onFilterChange()">
        <option *ngFor="let type of equalityTypes" [value]="type.value">
          {{ type.value }}
        </option>
      </select>
    </div>
  </div>


  <!-- Table -->
  <table class="data-table">
    <thead>
      <tr>
        <th>CSP</th>
        <th>CSP Name</th>
        <th>Product Code</th>
        <th>Quantity</th>
        <th>Price</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let group of data">
        <!-- Group Header Row -->
        <tr class="group-row">
          <td colspan="6">
            <strong>{{ group.customerServicePoint }} - {{ group.customerServicePointName }}</strong> |
            <span>Total Price: {{ group.totalPrice | number: '1.2-2' }}</span>
          </td>
        </tr>
  
        <!-- Subrecords -->
        <ng-container *ngFor="let record of group.records">
          <tr>
            <td>{{ group.customerServicePoint }}</td>
            <td>{{ group.customerServicePointName }}</td>
            <td>{{ record.supplierProductCode }}</td>
            <td>
              {{ record.quantity }} ({{ record.details.length || 0 }})
            </td>
            <td>{{ record.price }}</td>
            <td>
              <button (click)="toggleDetails(record)">
                {{ record.expanded ? 'Hide Details' : 'Show Details' }}
              </button>
            </td>
          </tr>
  
          <!-- Expandable Details -->
          <tr *ngIf="record.expanded">
            <td colspan="6">
              <div class="details-container">
                <h4>Details:</h4>
                <table class="details-table">
                  <thead>
                    <tr>
                      <th>Serial Number</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let detail of record.details">
                      <td>{{ detail.serialNumber }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
  </table>

  <!-- Pagination -->
  <div class="pagination-container">
    <!-- Page Navigation -->
    <div class="pagination">
      <button [disabled]="metadata.page === 1" (click)="onPageChange(metadata.page - 1)">
        Previous
      </button>

      <!-- Jump to Page Input -->
      <span>
        Page
        <input
          type="number"
          class="page-jump"
          [(ngModel)]="customPageInput"
          (keydown.enter)="jumpToPage()"
          [min]="1"
          [max]="metadata.totalPages"
        />
        of {{ metadata.totalPages }}
      </span>

      <button
        [disabled]="data.length < metadata.pageSize"
        (click)="onPageChange(metadata.page + 1)"
      >
        Next
      </button>
    </div>
  
    <!-- Records per Page Selection -->
    <div class="page-size-selector">
      <span>Records per page:</span>
      <button
        *ngFor="let size of pageSizeOptions"
        [class.active]="metadata.pageSize === size"
        (click)="setPageSize(size)"
      >
        {{ size }}
      </button>
    </div>
  </div>
</div>
