<div class="data-container">
  <!-- Filters -->
  <div class="filters-container">
    <!-- Filter by Document Number -->
    <div class="filter-item">
      <label for="document-filter">Docuemnt:</label>
      <input
        id="document-filter"
        type="text"
        [(ngModel)]="documentNumberFilter"
        (input)="onFilterChange()"
        placeholder="Search Document">
    </div>

    <!-- Filter by Part Number -->
    <div class="filter-item">
      <label for="part-number-filter">Part Number:</label>
      <input
        id="part-number-filter"
        type="text"
        [(ngModel)]="partNumberFilter"
        (input)="onFilterChange()"
        placeholder="Search Part Number">
    </div>

    <!-- Filter by Serial Number -->
    <div class="filter-item">
      <label for="serial-number-filter">Serial Number:</label>
      <input
        id="serial-number-filter"
        type="text"
        [(ngModel)]="serialNumberFilter"
        (input)="onFilterChange()"
        placeholder="Search Serial Number">
    </div>

    <!-- Filter by Received Status (True/False) -->
    <div class="filter-item">
      <label for="received-filter">Received:</label>
      <select id="received-filter" [(ngModel)]="receivedFilter" (change)="onFilterChange()">
        <option [value]="null">All</option>
        <option [value]="true">Yes</option>
        <option [value]="false">No</option>
      </select>
    </div>
  </div>

  <!-- Table -->
  <table class="data-table">
    <thead>
      <tr>
        <th>Document Number</th>
        <th>Part Number</th>
        <th>Serial Number</th>
        <th>Received</th>
        <th>Document Date</th>
        <th>Received Date</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of data">
        <td>{{ item.documentNumber }}</td>
        <td>{{ item.partNumber }}</td>
        <td>{{ item.serialNumber }}</td>
        <td class="status">
          <span [class.received]="item.isReceived" [class.not-received]="!item.isReceived">
            {{ item.isReceived ? 'Yes' : 'No' }}
          </span>
        </td>
        <td>{{ item.documentDate | date: 'dd/MM/yyyy' }}</td>
        <td>{{ item.receivedDate | date: 'dd/MM/yyyy' }}</td>
      </tr>
    </tbody>
  </table>

  <!-- Pagination -->
  <div class="pagination-container">
    <!-- Page Navigation -->
    <div class="pagination">
      <button [disabled]="metadata.page === 1" (click)="onPageChange(metadata.page - 1)">
        Previous
      </button>

      <!-- Jump to Page Input -->
      <span>
        Page
        <input
          type="number"
          class="page-jump"
          [(ngModel)]="customPageInput"
          (keydown.enter)="jumpToPage()"
          [min]="1"
          [max]="metadata.totalPages"
        />
        of {{ metadata.totalPages }}
      </span>

      <button
        [disabled]="data.length < metadata.pageSize"
        (click)="onPageChange(metadata.page + 1)"
      >
        Next
      </button>
    </div>
  
    <!-- Records per Page Selection -->
    <div class="page-size-selector">
      <span>Records per page:</span>
      <button
        *ngFor="let size of pageSizeOptions"
        [class.active]="metadata.pageSize === size"
        (click)="setPageSize(size)"
      >
        {{ size }}
      </button>
    </div>
  </div>
</div>
