import { Component, inject, OnInit } from '@angular/core';
import { IMaterialMovementRecord } from '../../../interfaces/vmi/material-movement-record.interface';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IPagination, Pagination } from '../../../interfaces/shared/pagination.interface';
import { MaterialMovementRecordService } from '../../../services/vmi/material-movement-record.service';
import { IPaginatedResponse } from '../../../interfaces/shared/paginated-response.interface';

@Component({
  selector: 'app-material-movement-records',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule
  ],
  templateUrl: './material-movement-records.component.html',
  styleUrl: './material-movement-records.component.sass'
})
export class MaterialMovementRecordsComponent implements OnInit {
  private materialMovementRecordService = inject(MaterialMovementRecordService);

  documentNumberFilter: string = '';
  partNumberFilter: string = '';
  serialNumberFilter: string = '';
  receivedFilter: boolean | null = null;
  
  metadata: IPagination = new Pagination();
  pageSizeOptions = [50, 100, 150, 200];
  customPageInput: number = 1;

  data: IMaterialMovementRecord[] = [];

  ngOnInit(): void {
    this.fetchData();
  }

  fetchData(): void {
    this.materialMovementRecordService
      .getMaterialMovementRecords(
        this.documentNumberFilter,
        this.partNumberFilter,
        this.serialNumberFilter,
        this.receivedFilter,
        this.metadata
      ).subscribe((response: IPaginatedResponse<IMaterialMovementRecord>) => {
        this.data = response.result;
        this.metadata = response.metadata;
      });      
  }

  onFilterChange(): void {
    this.fetchData();
  }

  onPageChange(page: number): void {
    if (page >= 1 && page <= this.metadata.totalPages) {
      this.metadata.page = page;
      this.customPageInput = page;
      this.fetchData();
    }
  }

  setPageSize(size: number): void {
    this.metadata.pageSize = size;
    this.metadata.page = 1;
    this.fetchData();
  }

  jumpToPage(): void {
    const page = Number(this.customPageInput);
    if (page >= 1 && page <= this.metadata.totalPages) {
      this.onPageChange(page);
    }
  }
}
