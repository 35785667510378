import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { BackendError } from "../../interfaces/backend-error-interface";
import { ToastService } from "../toast.service";

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingService {
  private errorOccurred = false;
  
  constructor(private toastService: ToastService) {}

  handleError(error: HttpErrorResponse): Observable<never> {
    this.errorOccurred = true;
    let errorMessage = "An unknown error occurred!";

    if (error.error instanceof ErrorEvent) {
      // Client-side or network error
      errorMessage = `Client-side error: ${error.error.message}`;
    } else if (error.status !== 0) {
      // Backend error
      const backendError: BackendError = error.error;
      errorMessage = this.getErrorMessage(backendError);
    }

    this.toastService.dangerToast(errorMessage);

    // Log the error or send it to the server
    console.error(errorMessage);
    
    // Return an observable with a user-facing error message
    return throwError(() => new Error(errorMessage));
  }

  hasErrorOccurred(): boolean {
    return this.errorOccurred;
  }

  resetErrorFlag(): void {
    this.errorOccurred = false;
  }

  private getErrorMessage(backendError: BackendError): string {
    switch (backendError.code) {
      case 'Error.NullValue':
        return "The specified result value is null.";
      
      default:
        return backendError.message || 'An unexpected error occurred.'; 
    }
  }
}