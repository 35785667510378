import { Injectable, inject } from "@angular/core";
import { URLHelper } from "../helpers/url.helper";
import { HttpClient } from "@angular/common/http";
import { map, Observable, tap } from "rxjs";
import { ToastService } from "./toast.service";

@Injectable()
export class DownloadProductsService {
  private http = inject(HttpClient)
  private urlHelper = inject(URLHelper);
  private toastService = inject(ToastService);

  downloadProduct(vendorLinkBase: string, fileName: string): Observable<{blob: Blob, url: string}> {
    let url = this.urlHelper.ProductDownloadLink
      .replace('{vendorLinkBase}', vendorLinkBase)
      .replace('{fileName}', fileName);
    
    return this.http.get(url, { responseType: 'blob' })
      .pipe(
        map((data: Blob) => {
          return { blob: data, url: url };
        }),
        tap(() => this.toastService.successToast("Product has been generated."))
      )
  }
}