import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DispatchType } from '../../../enums/dispatch-type.enum';
import { FileType } from '../../../enums/file-type.enum';
import { CommonModule } from '@angular/common';
import { VendorService } from '../../../services/vendor.service';
import { IVendorRequest, IVendorSftpRequest } from '../../../interfaces/requests/vendor-request.interface';
import { ReceiversFormChildComponent } from "./receivers-form-child/receivers-form-child.component";
import { SftpSettingsFormChildComponent } from "./sftp-settings-form-child/sftp-settings-form-child.component";
import { KomsaApiHttpService } from '../../../services/komsa-api-http.service';

@Component({
    selector: 'app-add-vendor-form',
    standalone: true,
    templateUrl: './add-vendor-form.component.html',
    styleUrl: './add-vendor-form.component.sass',
    imports: [
        ReactiveFormsModule,
        RouterModule,
        CommonModule,
        FormsModule,
        ReceiversFormChildComponent,
        SftpSettingsFormChildComponent
    ]
})
export class AddVendorFormComponent implements OnInit {
  private komsaApiHttpService = inject(KomsaApiHttpService);
  private vendorService = inject(VendorService);
  
  fb = inject(FormBuilder);
  DispatchTypeEnum = DispatchType;
  dispatchTypes: string[] = Object.values(DispatchType);
  fileTypes: string[] = Object.values(FileType);

  showReceiversFormChildComponent: boolean = false;
  showSftpsettingsFormChildComponent: boolean = false;
  receivers: string[] | null = null;
  vendorSftpSettings: IVendorSftpRequest | null = null;
  form!: FormGroup;
  vendorGroup!: FormGroup;
  
  ngOnInit(): void {
    this.vendorGroup = this.fb.group({
      komsaCode: new FormControl<string>('', [Validators.required, Validators.min(3), Validators.max(30)]),
      secret: new FormControl<string>('', [Validators.required, Validators.min(3), Validators.max(30)]),
      dispatchType: new FormControl<string>(DispatchType.Email, Validators.required),
      fileType: new FormControl<string>(FileType.Xml, Validators.required),
    });
  
    this.form = this.fb.group({
      vendorGroup: this.vendorGroup,
    });
  
    this.ShowFormBasedOnDispatchType(this.vendorGroup.getRawValue().dispatchType);
  
    this.vendorGroup.get('dispatchType')?.valueChanges.subscribe((dispatchType: DispatchType) => {
      this.ShowFormBasedOnDispatchType(dispatchType);
    });
    
  }

  private ShowFormBasedOnDispatchType(dispatchType: DispatchType) {
    switch (dispatchType) {
      case DispatchType.Email:
        this.showReceiversFormChildComponent = true;
        this.showSftpsettingsFormChildComponent = false;
        this.form.removeControl('sftpSettingsGroup');
        break;
      case DispatchType.Sftp:
        this.showSftpsettingsFormChildComponent = true;
        this.showReceiversFormChildComponent = false;
        this.form.removeControl('receiverGroup');
        break;
      case DispatchType.Api:
        this.showSftpsettingsFormChildComponent = false;
        this.showReceiversFormChildComponent = false;
        this.form.removeControl('receiverGroup');
        this.form.removeControl('sftpSettingsGroup');
    }
  }

  onChange(newForm: FormGroup): void {
    this.form = newForm;
  }

  onSubmit(): void {
    if (this.showReceiversFormChildComponent) {
      this.receivers = this.form.get('receiverGroup')?.getRawValue().receivers;
    } else if (this.showSftpsettingsFormChildComponent) {
      this.vendorSftpSettings = {
        host: this.form.get('sftpSettingsGroup')?.getRawValue().host,
        user: this.form.get('sftpSettingsGroup')?.getRawValue().user,
        password: this.form.get('sftpSettingsGroup')?.getRawValue().password,
        port: this.form.get('sftpSettingsGroup')?.getRawValue().port,
        folder: this.getSftpWithAddedSubnameForSharePortalHost(),
      }
    }

    const vendor: IVendorRequest = {
      komsaCode: this.form.get('vendorGroup')?.getRawValue().komsaCode,
      secret: this.form.get('vendorGroup')?.getRawValue().secret,
      dispatchType: this.form.get('vendorGroup')?.getRawValue().dispatchType,
      fileType: this.form.get('vendorGroup')?.getRawValue().fileType,
      receivers: this.receivers ?? null,
      vendorSftpSettings: this.vendorSftpSettings ?? null
    };
    
    this.vendorService
      .addVendor(vendor)
      .subscribe();

    this.form.reset();
    this.vendorGroup.reset({
      dispatchType: DispatchType.Email,
      fileType: FileType.Xml,
    });
  }

  private getSftpWithAddedSubnameForSharePortalHost(): string {
    const folder = this.form.get('sftpSettingsGroup')?.getRawValue().folder;

    if (this.form.get('sftpSettingsGroup')?.getRawValue().host !== 'share-portal.de')
      return folder;
    
    const env = this.komsaApiHttpService.environment;
    
    if (env === 'PROD')
      return '/Live/' + folder;
    
    if (env === 'STAGING')
      return '/Test/' + folder;
    
    return '/Test/' + folder;
  }
}
