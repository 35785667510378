import { Component, Input, OnInit, inject } from '@angular/core';
import { ProductService } from '../../../../services/product.service';
import { IProductResponse } from '../../../../interfaces/responses/product-response.interface';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-preview-products',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './preview-products.component.html',
  styleUrl: './preview-products.component.sass'
})
export class PreviewProductsComponent implements OnInit {
  private productService = inject(ProductService);

  products: IProductResponse[] = []
  vendorId!: string; 

  @Input()
  set id(vendorId: string) {
    this.vendorId = vendorId;
  }

  ngOnInit(): void {
    this.productService
      .getProducts(this.vendorId)
      .subscribe(response => this.products = response);
  }
}
