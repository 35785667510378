<div class="container mt-5">
  <h2>Product selection</h2>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">

    <!-- Warehouses -->
    <div class="row">
      <div class="col">
        <div class="navbar">
          <label class="navbar-brand">Warehouses</label>
        </div>
        <ul class="custom-dropdown-menu custom-dropdown-menu-below-divider">
          <li *ngFor="let warehouse of warehouses" [value]="warehouse.symbol" (click)="addWarehouse(warehouse)">
            <a class="custom-dropdown-item" [ngClass]="{'disable' : warehouse.isChecked}">{{ warehouse.name }} ({{warehouse.symbol}})</a>
          </li>
        </ul>
      </div>
      <div class="col">
        <div class="navbar">
          <label class="navbar-brand">Selected Warehouses</label>
        </div>
        <span class="badge text-bg-light" *ngFor="let item of warehouseArray.controls; let i = index">
          <h5>
            {{warehouse(item.value).symbol}}
            <span class="remove-sign" (click)="removeWarehouse(i)">&times;</span>
          </h5>
        </span>
      </div>
    </div>

    <!-- Product Groups -->
    <div class="navbar">
      <label class="navbar-brand">Product group table</label>
      <div class="d-flex">
        <a class="btn btn-primary" (click)="addProductGroup()">+</a>
      </div>
    </div>
    <table class="table table-bordered table-striped">
      <thead>
        <tr>
          <th scope="col" class="col-md-1 text-center">#</th>
          <th scope="col">Code</th>
          <th scope="col" class="col-md-2">Is Active</th>
          <th scope="col" class="col-md-2">Action</th>
        </tr>
      </thead>
      <tbody formArrayName="productGroupArray">
        <tr *ngFor="let control of productGroupArrayControl.controls; let i = index" [formGroupName]="i">
          <th scope="row" class="text-center align-middle">{{i+1}}</th>
          <td class="align-middle" >
            <input *ngIf="control.value.isModifying" type="text" class="form-control" formControlName="code">
            <span *ngIf="!control.value.isModifying">{{control.value.code}}</span>
          </td>
          <td class="align-middle">
            <div *ngIf="control.value.isModifying" class="row">
              <div class="col-auto">
                <div class="form-check">
                  <input type="radio" class="form-check-input" formControlName="isActive" [value]="true">
                  <label class="form-check-label">Yes</label>
                </div>
              </div>
              <div class="col-auto">
                <div class="form-check">
                  <input type="radio" class="form-check-input" formControlName="isActive" [value]="false">
                  <label class="form-check-label">No</label>
                </div>
              </div>
            </div>
            <span *ngIf="!control.value.isModifying">{{control.value.isActive | booleanToYesNo}}</span>
          </td>
          <td class="align-middle">
            <div *ngIf="!control.value.isModifying">
              <a class="btn btn-primary" (click)="modifyProductGroup(i)">Modify</a>
              <a class="btn btn-danger" (click)="removeProductGroup(i)">Remove</a>
            </div>
            <div *ngIf="control.value.isModifying">
              <a class="btn btn-primary" (click)="stopProductGroupModification(i)">Accept</a>
              <a class="btn btn-danger" (click)="removeProductGroup(i)">Remove</a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Products -->
    <div class="navbar">
      <label class="navbar-brand">Product table</label>
      <div class="d-flex">
        <a class="btn btn-primary" (click)="addProduct()">+</a>
      </div>
    </div>
    <table class="table table-bordered table-striped" formArrayName="productArray">
      <thead>
        <tr>
          <th scope="col" class="col-md-1 text-center">#</th>
          <th scope="col">Code</th>
          <th scope="col" class="col-md-2">Is Excluded</th>
          <th scope="col" class="col-md-2">Is Active</th>
          <th scope="col" class="col-md-2">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let control of productArray.controls; let i = index" [formGroupName]="i">
          <th scope="row" class="text-center align-middle">{{i+1}}</th>
          <td class="align-middle">
            <input *ngIf="control.value.isModifying" type="text" class="form-control" formControlName="code">
            <span *ngIf="!control.value.isModifying">{{control.value.code}}</span>
          </td>
          <td class="align-middle">
            <div *ngIf="control.value.isModifying" class="row">
              <div class="col-auto">
                <div class="form-check">
                  <input type="radio" class="form-check-input" formControlName="isExcluded" [value]="true">
                  <label class="form-check-label">Yes</label>
                </div>
              </div>
              <div class="col-auto">
                <div class="form-check">
                  <input type="radio" class="form-check-input" formControlName="isExcluded" [value]="false">
                  <label class="form-check-label">No</label>
                </div>
              </div>
            </div>
            <span *ngIf="!control.value.isModifying">{{control.value.isExcluded | booleanToYesNo}}</span>
          </td>
          <td class="align-middle">
            <div *ngIf="control.value.isModifying" class="row">
              <div class="col-auto">
                <div class="form-check">
                  <input type="radio" class="form-check-input" formControlName="isActive" [value]="true">
                  <label class="form-check-label">Yes</label>
                </div>
              </div>
              <div class="col-auto">
                <div class="form-check">
                  <input type="radio" class="form-check-input" formControlName="isActive" [value]="false">
                  <label class="form-check-label">No</label>
                </div>
              </div>
            </div>
            <span *ngIf="!control.value.isModifying">{{control.value.isActive | booleanToYesNo}}</span>
          </td>
          <td class="align-middle">
            <div *ngIf="control.value.isModifying">
              <a class="btn btn-primary" (click)="stopProductModification(i)">Accept</a>
              <a class="btn btn-danger" (click)="removeProduct(i)">Remove</a>
            </div>
            <div *ngIf="!control.value.isModifying">
              <a class="btn btn-primary" (click)="modifyProduct(i)">Modify</a>
              <a class="btn btn-danger" (click)="removeProduct(i)">Remove</a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <button type="submit" class="btn btn-primary">Save</button>
    <a [routerLink]="['..']" class="btn btn-secondary">Back</a>
  </form>
</div>