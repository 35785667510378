import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { URLHelper } from "../helpers/url.helper";
import { ILoadedOrderFilter } from "../interfaces/filters.interface";
import { Observable } from "rxjs";
import { IPaginatedLoadedOrderResponse } from "../interfaces/responses/loaded-order-response.interface";

@Injectable({
  providedIn: 'root'
})
export class LoadedOrderService {
  private http = inject(HttpClient);
  private urlHelper = inject(URLHelper);

  getLoadedOrders(
    position: number,
    limit: number,
    filters: ILoadedOrderFilter
  ): Observable<IPaginatedLoadedOrderResponse> {
    var fromDateString = filters.fromDate?.getDate()
      + '-' + (filters.fromDate ? filters.fromDate?.getMonth() + 1 : '')
      + '-' + filters.fromDate?.getFullYear();
    var toDateString = filters.toDate?.getDate()
      + '-' + (filters.toDate ? filters.toDate?.getMonth() + 1 : '')
      + '-' + filters.toDate?.getFullYear();

    return this.http.get<IPaginatedLoadedOrderResponse>(
      this.urlHelper.GetLoadedOrders,
      { params: {
        position: position,
        limit: limit,
        isCompleted: filters.isCompleted ? filters.isCompleted : '',
        documentStatus: filters.documentStatus,
        documentNumber: filters.documentNumber,
        deliveryNote: filters.deliveryNote,
        orderNumber: filters.orderNumber,
        fromDate: filters.fromDate ? fromDateString : '',
        toDate: filters.toDate ? toDateString : ''
      }});
  }
}