import { isPlatformBrowser } from "@angular/common";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class AppCookieService {
  private cookieStore: any = {};

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object) {
      if (isPlatformBrowser(this.platformId)) {
        this.parseCookies(document.cookie);
      }
  }

  public parseCookies(cookies: string = ''): void {
    if (isPlatformBrowser(this.platformId)) {
      if (cookies === '') {
       cookies = document.cookie;
      }
    }
    this.cookieStore = {};
    if (!!cookies === false) {
      return;
    }
    const cookiesArr = cookies.split(';');
    for (const cookie of cookiesArr) {
      const cookieArr = cookie.split('=');
      this.cookieStore[cookieArr[0].trim()] = cookieArr[1];
    }
  }

  get(key: string): string | null {
    this.parseCookies();
    return !!this.cookieStore[key] ? this.cookieStore[key] : null;
  }

  remove(key: string): void {
    if (isPlatformBrowser(this.platformId)) {
      document.cookie = `${key} = ; expires=Thu, 1 jan 1990 12:00:00 UTC; path=/`;
    }
  }

  set(key: string, value: string): void {
    if (isPlatformBrowser(this.platformId)) {
      document.cookie = key + '=' + (value || '');
    }
  }
}