import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { URLHelper } from "../helpers/url.helper";
import { Observable } from "rxjs";
import { IExportedOrderFilter } from "../interfaces/filters.interface";
import { IPaginatedExportedOrderResponse } from "../interfaces/responses/exported-order-response.interface";

@Injectable({
  providedIn: 'root'
})
export class ExportedOrderService {
  private http = inject(HttpClient);
  private urlHelper = inject(URLHelper);

  getExportedOrders(
    position: number,
    limit: number,
    filters: IExportedOrderFilter
    ): Observable<IPaginatedExportedOrderResponse> {
    var fromDateString = filters.fromDate?.getDate()
      + '-' + (filters.fromDate ? filters.fromDate?.getMonth() + 1 : '')
      + '-' + filters.fromDate?.getFullYear();
    var toDateString = filters.toDate?.getDate()
      + '-' + (filters.toDate ? filters.toDate?.getMonth() + 1 : '')
      + '-' + filters.toDate?.getFullYear();

    return this.http.get<IPaginatedExportedOrderResponse>(
      this.urlHelper.GetExportedOrders,
      { params: {
          position: position,
          limit: limit,
          isLoaded: filters.isLoaded ? filters.isLoaded : '',
          orderNumber: filters.orderNumber,
          fromDate: filters.fromDate ? fromDateString : '',
          toDate: filters.toDate ? toDateString : ''
      }});
  }
}