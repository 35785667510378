import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLinkActive, RouterModule } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { JWTTokenService } from '../../services/jwt-token.service';
import { Role } from '../../enums/role.enum';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    RouterLinkActive
  ],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.sass'
})
export class SidebarComponent {
  user: string | null;
  role: string | null;
  isLoggedIn: boolean = false;

  constructor(
    private authSevice: AuthService,
    private jwtTokenService: JWTTokenService) {
      this.user = this.jwtTokenService.getUser();
      this.role = this.jwtTokenService.getRole();

      if (this.user) {
        this.isLoggedIn = true;
      }
  }

  logout(): void {
    this.authSevice.logout();
  }

  showEdi(): boolean {
    if (this.role?.toLowerCase() === Role.SuperAdmin) {
      return true;
    }

    if (this.role?.toLowerCase() === Role.Admin) {
      return true;
    }

    if (this.role?.toLowerCase() === Role.EdiUser) {
      return true;
    }

    return false;
  }

  showApi(): boolean {
    if (this.role?.toLowerCase() === Role.SuperAdmin) {
      return true;
    }

    if (this.role?.toLowerCase() === Role.Admin) {
      return true;
    }

    if (this.role?.toLowerCase() === Role.ApiUser) {
      return true;
    }

    return false;
  }

  showVmi(): boolean {
    if (this.role?.toLowerCase() === Role.SuperAdmin) {
      return true;
    }

    if (this.role?.toLowerCase() === Role.Admin) {
      return true;
    }

    if (this.role?.toLowerCase() === Role.VmiUser) {
      return true;
    }

    return false;
  }
}
