import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { UserInterface } from "../interfaces/user.interface";
import { isPlatformBrowser } from "@angular/common";
import { jwtDecode } from "jwt-decode";
import { TokenInterface } from "../interfaces/token.interface";

@Injectable({
  providedIn: 'root'
})
export class CurrentUserService {
  private accessToken: string | null = null
  private currentUserSubject: BehaviorSubject<UserInterface | null | undefined> = new BehaviorSubject<UserInterface | null | undefined>(undefined)
  currentUser$: Observable<UserInterface | null | undefined> = this.currentUserSubject.asObservable();

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  setCurrentUser(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.accessToken = localStorage.getItem('token');
    }

    if (this.accessToken === '') {
      this.accessToken = null;
    }

    if (this.accessToken !== null) {
      const decodedToken = jwtDecode<TokenInterface>(this.accessToken)

      this.currentUserSubject.next({
        id: decodedToken.sub,
        login: decodedToken.name,
        expires: decodedToken.exp,
        isLoggedIn: true})
    } else {
      this.currentUserSubject.next(undefined);
    }
  }
  
  clearUser(): void {
    this.currentUserSubject.next(null);
  }
}