<fieldset [formGroup]="vendorSftpSettingsChildForm">
  <div class="form-floating mb-3">
    <input type="text" class="form-control" id="host" placeholder="Host" formControlName="host">
    <label for="host">Host</label>
  </div>

  <div class="form-floating mb-3">
    <input type="text" class="form-control" id="user" placeholder="User" formControlName="user">
    <label for="user">User</label>
  </div>

  <div class="form-floating mb-3">
    <input type="password" class="form-control" id="password" placeholder="Password" formControlName="password">
    <label for="password">Password</label>
  </div>

  <div class="form-floating mb-3">
    <input type="text" class="form-control" id="port" placeholder="Port" formControlName="port">
    <label for="port">Port</label>
  </div>

  <div class="form-floating mb-3">
    <input type="text" class="form-control" id="folder" placeholder="Folder" formControlName="folder">
    <label for="folder">Folder</label>
  </div>
</fieldset>