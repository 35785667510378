import { HttpClient, HttpParams } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { URLHelper } from "../../helpers/url.helper";
import { IPaginatedResponse } from "../../interfaces/shared/paginated-response.interface";
import { Observable } from "rxjs";
import { IPagination } from "../../interfaces/shared/pagination.interface";
import { ISale } from "../../interfaces/vmi/sale.interface";

@Injectable()
export class SaleService {
  private http = inject(HttpClient);
  private urlHelper = inject(URLHelper);

  getSales(
    warehouseDocument: string,
    customerServicePoint: string,
    supplierProductCode: string,
    deliveryDocumentNumber: string,
    serialNumber: string,
    documentDate: Date | null,
    pagination: IPagination
  ): Observable<IPaginatedResponse<ISale>> {
    let params = new HttpParams();
    params = params.append('warehouseDocument', warehouseDocument);
    params = params.append('customerServicePoint', customerServicePoint);
    params = params.append('supplierProductCode', supplierProductCode);
    params = params.append('deliveryDocumentNumber', deliveryDocumentNumber);
    params = params.append('serialNumber', serialNumber);
    params = params.append('page', pagination.page.toString());
    params = params.append('pageSize', pagination.pageSize.toString());

    if (documentDate !== null) {
      params = params.append('documentDate', documentDate.toString());
    }

    return this.http
      .get<IPaginatedResponse<ISale>>(
        this.urlHelper.GetSales, { params });
  }
}