import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-edit-price-modal',
  standalone: true,
  imports: [
    FormsModule
  ],
  templateUrl: './edit-price-modal.component.html',
  styleUrl: './edit-price-modal.component.sass'
})
export class EditPriceModalComponent {
  @Input() orderLine: any;
  @Input() show: boolean = false;
  @Output() save = new EventEmitter<number>();
  @Output() close = new EventEmitter<void>();

  newPrice?: number;

  ngOnChanges(): void {
    if (this.orderLine) {
      this.newPrice = this.orderLine.orderedUnitPrice;
    }
  }

  onSave(): void {
    this.save.emit(this.newPrice);
    this.onClose();
  }

  onClose(): void {
    this.show = false;
    this.close.emit();
  }
}
